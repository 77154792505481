/*
 * @Author: your name
 * @Date: 2021-10-13 18:25:32
 * @LastEditTime: 2021-10-21 11:08:02
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/file/buttons/share.tsx
 */
import { useState } from "react";
import { Button, Form, Input, message, Space } from "antd";
import ModalCollectionForm from "./../../utils/modalCollectionForm";

interface ShareButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const ShareButton = (props: ShareButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    const response = await props.post(props.postUrl, {
      fileList: props.selectedFiles,
      key: values.key,
      day: parseInt(values.day),
    });

    if (!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if (json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  };

  const formItems = () => {
    return (
      <>
        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
          <Form.Item
            label="Files"
            name={"files"}
            fieldKey={"files"}
            rules={[{ required: true, message: "Missing file" }]}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item label="Key" name="key" fieldKey="key">
            <Input placeholder="Key" />
          </Form.Item>
          <Form.Item label="Day" name="day" fieldKey="day">
            <Input placeholder="Day" />
          </Form.Item>
        </Space>
      </>
    );
  };

  const path = require("path");
  const initialValues = {
    files: props.selectedFiles.reduce(
      (previousValue, currentValue) =>
        `${path.basename(previousValue)} ${path.basename(currentValue)}`
    ),
    key: "",
    day: "",
  };
  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        Share
      </Button>
      <ModalCollectionForm
        title="Share"
        visible={visible}
        initialValues={initialValues}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default ShareButton;
