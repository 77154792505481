/*
 * @Author: your name
 * @Date: 2021-10-12 18:03:42
 * @LastEditTime: 2021-10-26 20:44:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/task/taskTable.tsx
 */
import React from "react";
import "../utils/common.css";
import { Table, Popconfirm, Space } from "antd";
import { Row } from "./interfaces";
import { getAntdTableSelections } from "../utils/utils";

interface Props {
  rows: Row[];
  onSelectedTaskChange: (keys: React.Key[]) => void;
  killTask: (key: React.Key) => void;
  gotoTask: (key: React.Key) => void;
  onRowClick: (row: Row, rowIndex: number | undefined) => void;
  onRowDoubleClick: (row: Row, rowIndex: number | undefined) => void;
  selectedRowKeys: React.Key[];
}

interface State {
  selectedRowKeys: React.Key[];
}

class TaskTable extends React.Component<Props, State> {
  // constructor(props: Props) {
  //   super(props);
  // }
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Created Time",
      dataIndex: "createdTime",
      key: "createdTime",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_: any, record: { key: React.Key }) =>
        this.props.rows.length >= 1 ? (
          <Space>
            <button type="button" className="button-like-a-link" onClick={() => this.props.gotoTask(record.key)}>
              Goto
            </button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => this.props.killTask(record.key)}
            >
              <button type="button" className="button-like-a-link">
                Delete
              </button>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];

  onSelectChange = (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.props.onSelectedTaskChange(selectedRowKeys);
  };

  componentDidMount() { }

  render() {
    const { selectedRowKeys, onSelectedTaskChange, onRowClick, onRowDoubleClick, rows } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      selections: getAntdTableSelections(
        onSelectedTaskChange,
        selectedRowKeys
      ),
    };
    return (
      <Table
        onRow={(record: Row, rowIndex: number | undefined) => {
          return {
            onClick: () => onRowClick(record, rowIndex),
            onDoubleClick: () => onRowDoubleClick(record, rowIndex),
          };
        }}
        style={{ width: "100%", overflow: "auto" }}
        columns={this.columns}
        dataSource={rows}
        rowSelection={rowSelection}
        pagination={false}
      />
    );
  }
}

export default TaskTable;
