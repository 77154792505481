import {useState} from "react";
import {Button, Form, Input, AutoComplete, message, Space} from "antd";
import ModalCollectionForm from "./../../utils/modalCollectionForm";

interface EmbedSubButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
  get: (urlString: string, query: any) => Promise<Response>;
  getUrl: string;
}

const EmbedSubButton = (props: EmbedSubButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    let parentDir = props.selectedFiles[0];
    parentDir = parentDir.endsWith("/") ? parentDir : parentDir + "/"

    let vidList = values.embed.map((embed: any) => parentDir + embed.vid);
    let subList = values.embed.map((embed: any) => parentDir + embed.sub);

    const response = await props.post(props.postUrl, {
      VidList: vidList,
      SubList: subList,
    });

    if (!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if (json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  };

  const formItems = () => {
    if (initialValues === undefined) return;
    const {subList} = initialValues;
    let subListOptions = subList.map((sub: string) => ({value: sub}))
    return (
      <Form.List name="embed">
        {(fields) => (
          <>
            {fields.map(({key, name, fieldKey, ...restField}) => (
              <Space
                key={key}
                style={{display: "flex", marginBottom: 8}}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  label="Video"
                  name={[name, "vid"]}
                  fieldKey={[fieldKey, "vid"]}
                  rules={[{required: true, message: "Missing video"}]}
                  style={{ width: "550px" }}
                >
                  <Input
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Subtitle"
                  name={[name, "sub"]}
                  fieldKey={[fieldKey, "sub"]}
                  rules={[{required: false}]}
                  style={{ width: "550px" }}
                >
                  <AutoComplete
                    options={subListOptions}
                    placeholder="subtitle"
                    filterOption={(inputValue, option) =>
                      option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    allowClear={true}
                  />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
    );
  };

  return (
    <div>
      <Button
        onClick={() => {
          const {get, getUrl, selectedFiles} = props;
          if (selectedFiles.length !== 1) return;
          get(getUrl, {dir: selectedFiles[0]})
            .then((response: Response) => response.json()).then((data) => {
              const {SubList, Vid2SubIdx} = data.pairs;

              let embed = [];
              for (const key in Vid2SubIdx) {
                embed.push({
                  vid: key,
                  sub: SubList[Vid2SubIdx[key]],
                });
              }

              setInitialValues({
                embed: embed,
                subList: SubList,
              });
              setVisible(true);
            }).catch((error) => {
              console.error("Error:", error);
            });
        }}
      >
        EmbedSub
      </Button>
      <ModalCollectionForm
        title="EmbedSub"
        visible={visible}
        initialValues={initialValues}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default EmbedSubButton;
