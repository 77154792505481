/*
 * @Author: your name
 * @Date: 2021-10-23 19:57:25
 * @LastEditTime: 2021-10-23 22:36:41
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/utils/musicPlayer.tsx
 */
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { PlaylistItem } from "../utils/interfaces";

interface MusicPlayerProps {
  playlist: PlaylistItem[];
}

const MusicPlayer = (props: MusicPlayerProps) => {
  const path = require("path");
  const playlist2AudioList = (playlist: PlaylistItem[]) => {
    return playlist.map((playlistItem: PlaylistItem) => ({
      name: path.basename(playlistItem.key),
      musicSrc: playlistItem.src,
      cover: "",
    }));
  };

  return (
    <>
      <ReactJkMusicPlayer
        audioLists={playlist2AudioList(props.playlist)}
        showLyric
        showMediaSession
        theme="auto"
        quietUpdate
        clearPriorAudioLists
        volumeFade={{ fadeIn: 500, fadeOut: 500 }}
      />
    </>
  );
};

export default MusicPlayer;
