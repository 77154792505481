import { useState } from "react";
import { Button, Form, Input, Select, Switch, message, Space } from "antd";
import ModalCollectionForm from "./../../utils/modalCollectionForm";
const { Option } = Select;

interface SyncButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
  mountPoints: string[];
  srcSelected: number;
  dstSelected: number;
}

const SyncButton = (props: SyncButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    let srcBackends = values.syncs.map((sync: any) => sync.srcMountPoint);
    let srcList = values.syncs.map((sync: any) => sync.src);
    let dstBackends = values.syncs.map((sync: any) => sync.dstMountPoint);
    let dstList = values.syncs.map((sync: any) => sync.dst);
    let isSyncs = values.syncs.map((sync: any) => sync.compSync);

    const response = await props.post(props.postUrl, {
      srcBackends: srcBackends,
      srcList: srcList,
      dstBackends: dstBackends,
      dstList: dstList,
      isSyncs: isSyncs,
    });

    if (!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if (json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  };

  const formItems = () => {
    let items = props.mountPoints;
    return (
      <Form.List name="syncs">
        {(fields) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space
                key={key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  label="SrcMountPoint"
                  name={[name, "srcMountPoint"]}
                  fieldKey={[fieldKey, "srcMountPoint"]}
                  rules={[{ required: true, message: "Missing srcMountPoint" }]}
                >
                  <Select>
                    {items.map((item: string) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Source File"
                  name={[name, "src"]}
                  fieldKey={[fieldKey, "src"]}
                  rules={[{ required: true, message: "Missing source" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="DstMountPoint"
                  name={[name, "dstMountPoint"]}
                  fieldKey={[fieldKey, "dstMountPoint"]}
                  rules={[{ required: true, message: "Missing dstMountPoint" }]}
                >
                  <Select>
                    {items.map((item: string) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Destination File"
                  name={[name, "dst"]}
                  fieldKey={[fieldKey, "dst"]}
                  rules={[{ required: true, message: "Missing Destination" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Completely Sync"
                  name={[name, "compSync"]}
                  fieldKey={[fieldKey, "compSync"]}
                >
                  <Switch />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
    );
  };

  const initialValues = {
    syncs: props.selectedFiles.map((file) => ({
      srcMountPoint: props.mountPoints[props.srcSelected],
      src: file,
      dstMountPoint: props.mountPoints[props.dstSelected],
      dst: file,
      compSync: false,
    })),
  };
  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        Sync
      </Button>
      <ModalCollectionForm
        title="Sync"
        visible={visible}
        initialValues={initialValues}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default SyncButton;
