/*
 * @Author: your name
 * @Date: 2021-10-15 15:43:03
 * @LastEditTime: 2021-10-26 20:44:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/shareManager/shareTable.tsx
 */

import React, { useState } from "react";
import "../utils/common.css";
import { Table, Popconfirm, Space, Tooltip } from "antd";
import { ShareRow } from "./interfaces";
import { getAntdTableSelections } from "../utils/utils";

interface ShareTableProp {
  rows: ShareRow[];
  deleteShare: (key: React.Key) => void;
  onSelectedShareChange: (keys: React.Key[]) => void;
  gotoShare: (key: React.Key) => void;
}

const ShareTable = (props: ShareTableProp) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: { key: React.Key; fullPaths: string }) =>
        props.rows.length >= 1 ? (
          <Tooltip title={record.fullPaths}>
            <span>{text}</span>
          </Tooltip>
        ) : null,
    },
    {
      title: "Key",
      dataIndex: "key_",
      key: "key_",
    },
    {
      title: "Expire",
      dataIndex: "expire",
      key: "expire",
    },
    {
      title: "Total Access Number",
      dataIndex: "totalAccessNumber",
      key: "totalAccessNumber",
    },
    {
      title: "Access Number",
      dataIndex: "accessNumber",
      key: "accessNumber",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: { key: React.Key }) =>
        props.rows.length >= 1 ? (
          <Space>
            <button
              type="button"
              className="button-like-a-link"
              onClick={() => props.gotoShare(record.key)}
            >
              Goto
            </button>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => props.deleteShare(record.key)}
            >
              <button type="button" className="button-like-a-link">
                Delete
              </button>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();
  const onSelectChange = (
    selectedRowKeys: React.Key[],
    _selectedRows: any[]
  ) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    props.onSelectedShareChange(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: getAntdTableSelections(setSelectedRowKeys, selectedRowKeys),
  };
  return (
    <Table
      style={{ width: "100%", overflow: "auto" }}
      columns={columns}
      dataSource={props.rows}
      rowSelection={rowSelection}
      pagination={false}
    />
  );
};

export default ShareTable;
