/*
 * @Author: your name
 * @Date: 2021-10-15 10:37:48
 * @LastEditTime: 2021-10-26 20:46:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/file/fileBreadcrumb.tsx
 */
import { Breadcrumb } from "antd";
import "../utils/common.css";
import { FileBreadcrumbInfo } from "./interfaces";

interface FileBreadcrumbProp {
  fileBreadcrumbInfos: FileBreadcrumbInfo[];
  goto: (path: string) => void;
}

const FileBreadcrumb = (props: FileBreadcrumbProp) => {
  return (
    <Breadcrumb>
      {props.fileBreadcrumbInfos.map(
        (fileBreadcrumbInfo: FileBreadcrumbInfo) => (
          <Breadcrumb.Item
            onClick={(e) => {
              e.preventDefault();
              props.goto(fileBreadcrumbInfo.path);
            }}
          >
            <button type="button" className="button-like-a-link">
              {fileBreadcrumbInfo.name}
            </button>
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};

export default FileBreadcrumb;
