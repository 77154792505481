import React from "react";
import "./fileManager.css";
import "../utils/common.css";
import Highlighter from "react-highlight-words";
import {Table, Input, Space, Button, Tag} from "antd";
import {SearchOutlined} from "@ant-design/icons";

import {FileRow, FileType} from "./interfaces";
import {MediaType} from "../utils/interfaces";
import {getAntdTableSelections, getMediaTypeByFileName} from "../utils/utils";

interface Props {
  locatedName?: string;
  data: FileRow[];
  selectedRowKeys: React.Key[];
  updateSelectedFiles: (selectedRowKeys: React.Key[]) => void;
  onRowClick: (row: FileRow, rowIndex: number | undefined) => void;
  onRowDoubleClick: (row: FileRow, rowIndex: number | undefined) => void;
  onLocatedOver?: () => void;
  getFileTypeImage: (fileType: string) => string;
  fileUrlBuilder: (file: string, accessToken: string) => string;
}

interface State {
  searchText: string;
  searchedColumn: string;
}

class FileTable extends React.PureComponent<Props, State> {
  rowRef;
  constructor(props: Props) {
    super(props);
    this.rowRef = React.createRef<HTMLTableRowElement>();
  }

  state = {
    searchText: "",
    searchedColumn: "name",
  };

  searchInput: Input = new Input({});
  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: (args: {
      setSelectedKeys: (selectedKeys: string[]) => void;
      selectedKeys: string[];
      confirm: (args?: {closeDropdown?: boolean}) => void;
      clearFilters: () => void;
    }) => {
      const {setSelectedKeys, selectedKeys, confirm, clearFilters} = args;
      return (
        <div style={{padding: 8}}>
          <Input
            ref={(node) => {
              node && (this.searchInput = node);
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{marginBottom: 8, display: "block"}}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                this.handleSearch(selectedKeys, confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}
            >
              Search
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{width: 90}}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({closeDropdown: false});
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    ),
    onFilter: (value: string | number | boolean, record: FileRow) => {
      if (typeof value !== "string") return false;
      let re = new RegExp(value);
      let recordStr = record[dataIndex as keyof FileRow];
      if (typeof recordStr !== "string") return false;
      return re.test(recordStr);
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text: string) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: FileRow, b: FileRow) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
      ...this.getColumnSearchProps("name"),
      render: (text: string, row: FileRow, index: number) => {
        return (
          <Space>
            <img alt="" src={this.props.getFileTypeImage(row.fileType)}></img>
            <a href={row.fileType === FileType.Folder ? `${encodeURIComponent(row.name)}/` : this.props.fileUrlBuilder(row.key, row.accessToken)} onClick={(e) => {
              this.props.onRowDoubleClick(row, index);
              e.preventDefault();
            }}>{text}</a>
            {row.fileType !== FileType.Folder &&
              getMediaTypeByFileName(text) !== MediaType.None && (
                <Tag color="cyan">Browsable</Tag>
              )}
          </Space>
        );
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      sorter: (a: FileRow, b: FileRow) => a.Size - b.Size,
    },
    {
      title: "Modified time",
      dataIndex: "modifiedTime",
      key: "modifiedTime",
      sorter: (a: FileRow, b: FileRow) =>
        Date.parse(a.ModTime) - Date.parse(b.ModTime),
    },
  ];

  handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({searchText: ""});
  };

  onSelectChange = (selectedRowKeys: React.Key[], _selectedRows: any[]) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    this.props.updateSelectedFiles(selectedRowKeys);
  };

  componentDidMount() {}

  handleTableChange = (sorter: any) => {
    console.log({
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  render() {
    const {selectedRowKeys} = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      selections: getAntdTableSelections(
        this.props.updateSelectedFiles,
        this.props.selectedRowKeys
      ),
    };

    return (
      <Table
        onRow={(record: FileRow, rowIndex: number | undefined) => {
          const locatedClassName = "located-file";
          if (record.name === this.props.locatedName) {
            setTimeout(() => {
              this.rowRef.current && this.rowRef.current.scrollIntoView();
            }, 100);
            setTimeout(() => {
              this.props.onLocatedOver && this.props.onLocatedOver();
            }, 8000);
            return {
              className: locatedClassName,
              ref: this.rowRef,
              onClick: () => this.props.onRowClick(record, rowIndex),
              onDoubleClick: () =>
                this.props.onRowDoubleClick(record, rowIndex),
            };
          }
          return {
            onClick: () => this.props.onRowClick(record, rowIndex),
            onDoubleClick: () => this.props.onRowDoubleClick(record, rowIndex),
          };
        }}
        columns={this.columns}
        dataSource={this.props.data}
        pagination={false}
        onChange={this.handleTableChange}
        rowSelection={rowSelection}
      />
    );
  }
}

export default FileTable;
