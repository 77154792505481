import { useState } from 'react';
import { Button, Form, Input, Select, Switch, message } from 'antd';
import ModalCollectionForm from './../../utils/modalCollectionForm';
const { Option, OptGroup } = Select;

interface ArchiveButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const ArchiveButton = (props: ArchiveButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    const response = await props.post(props.postUrl, {
      fileList: props.selectedFiles,
      arcType: values.arcType,
      isDeleteOrigin: values.isDeleteOrigin
    });

    if(!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if(json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  }

  const formItems = () => {
    return (
      <>
        <Form.Item name="files" label="Files" >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item name="arcType" label="Archive Type" >
          <Select defaultValue="tar" style={{ width: 200 }}>
            <OptGroup label="Archive">
              <Option value="tar">TAR(tape archive)</Option>
            </OptGroup>
            <OptGroup label="Compress">
              <Option value="zstd">Zstandard</Option>
              <Option value="gz">GNU Gzip</Option>
              <Option value="bz2">Bzip2</Option>
              <Option value="xz">XZ Utils(LZMA Utils)</Option>
              <Option value="7z">7Z(7-Zip archive)</Option>
            </OptGroup>
          </Select>
        </Form.Item>
        <Form.Item label="Delete Origin" name="isDeleteOrigin" valuePropName="checked">
          <Switch />
        </Form.Item>
      </>
    )
  }

  const path = require('path');
  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        Archive
      </Button>
      <ModalCollectionForm
        title="Archive"
        visible={visible}
        initialValues={{files: props.selectedFiles.reduce((previousValue, currentValue) => `${path.basename(previousValue)} ${path.basename(currentValue)}`), arcType: "tar", isDeleteOrigin: true}}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default ArchiveButton;
