import { Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

interface UnwrapDirectoryButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const UnwrapDirectoryButton = (props: UnwrapDirectoryButtonProps) => {

  const unwrap = async (dirs: string[]) => {
    const response = await props.post(props.postUrl, {
      DirList: dirs, 
    });

    if(!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if(json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
  }

  const path = require('path');
  const showUnwrapConfirm = () => {
    confirm({
      title: 'Are you sure unwrap these directories?',
      icon: <ExclamationCircleOutlined />,
      content: props.selectedFiles.map(file => path.basename(file)).reduce((previousValue, currentValue) => `${previousValue}, ${currentValue}`),
      onOk() {
        unwrap(props.selectedFiles);
      },
    });
  }

  return (
    <>
      <Button onClick={showUnwrapConfirm}>
        Unwrap
      </Button>
    </>
  );
};

export default UnwrapDirectoryButton;
