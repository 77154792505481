import { useState } from 'react';
import { Button, Form, Input, Switch, message, Space } from 'antd';
import ModalCollectionForm from './../../utils/modalCollectionForm';

interface ExtractButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const ExtractButton = (props: ExtractButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    let passwords = values.tasks.map((task: any) => task.password);
    let isDeleteOrigins = values.tasks.map((task: any) => task.isDeleteOrigin);
    let isNestedExtracts = values.tasks.map((task: any) => task.isNestedExtract);

    const response = await props.post(props.postUrl, {
      fileList: props.selectedFiles,
      passwords: passwords,
      isDeleteOrigins: isDeleteOrigins,
      isNestedExtracts: isNestedExtracts,
      isSmartExtracts: Array(props.selectedFiles.length).fill(true)
    });

    if (!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if (json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  }

  const formItems = () => {
    return (
      <Form.List name="tasks">
        {(fields) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  label="File"
                  name={[name, 'file']}
                  fieldKey={[fieldKey, 'file']}
                  rules={[{ required: true, message: 'Missing file' }]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Password"
                  name={[name, 'password']}
                  fieldKey={[fieldKey, 'password']}
                >
                  <Input placeholder="Password" />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Nested Extract"
                  name={[name, 'isNestedExtract']}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="Delete Origin"
                  name={[name, 'isDeleteOrigin']}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
    )
  }

  const path = require('path');
  const initialValues = {
    tasks:
      props.selectedFiles.map(file => ({
        file: path.basename(file),
        password: "",
        isDeleteOrigin: true,
        isNestedExtract: true,
      })),
  }
  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        Extract
      </Button>
      <ModalCollectionForm
        title="Extract"
        visible={visible}
        initialValues={initialValues}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default ExtractButton;
