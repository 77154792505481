import {Form, Input, Button, Image, message} from 'antd';
import {useState} from 'react';

interface AListDownloadTaskProp {
  post: (url: string, data: any, timeoutInMillisecond: number) => Promise<Response>;
  postUrl: string;
}

const AListDownloadMsgKey = 'AListDownloadMsgKey';

const AListDownloadTask = (props: AListDownloadTaskProp) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const timeoutInSec = 10;
  const handleFormSubmit = (value: any) => {
    setBtnDisabled(true);
    message.loading({content: "Starting AList Download task...", key: AListDownloadMsgKey, duration: timeoutInSec});
    const {post, postUrl} = props;
    post(postUrl, {
      filename: value.filename,
    }, timeoutInSec * 1000)
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          message.error({content: data.err, key: AListDownloadMsgKey});
        } else {
          message.success({content: data.msg, key: AListDownloadMsgKey});
        }
      })
      .catch((err) => {
        message.error({content: err.message, key: AListDownloadMsgKey});
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };
  return (
    <>
      <Image></Image>
      <h3>AList Download Task</h3>
      <Form
        name="alistDownloadNewTask"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={handleFormSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Filename"
          name="filename"
          rules={[{required: true, message: "Please input filename"}]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit" disabled={btnDisabled}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AListDownloadTask;
