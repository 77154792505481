/*
 * @Author: your name
 * @Date: 2021-10-15 15:52:08
 * @LastEditTime: 2021-10-21 11:09:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/shareManager/modShareModalBtn.tsx
 */
import { useState } from "react";
import { Button, Form, Input, Switch, message, Space } from "antd";
import ModalCollectionForm from "../utils/modalCollectionForm";

interface ModShareButtonProps {
  selectedShareUrls: string[];
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
  refresh: () => void;
}

const ModShareButton = (props: ModShareButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    let urls = props.selectedShareUrls;
    let keys = values.shares.map((task: any) => task.key);
    let days = values.shares.map((task: any) => parseInt(task.day));
    let isDeletes = values.shares.map((task: any) => task.isDelete);

    const response = await props.post(props.postUrl, {
      urls,
      keys,
      days,
      isDeletes,
    });

    if (!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if (json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
        props.refresh();
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  };

  const formItems = () => {
    return (
      <Form.List name="shares">
        {(fields) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space
                key={key}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
              >
                <Form.Item
                  {...restField}
                  label="File"
                  name={[name, "file"]}
                  fieldKey={[fieldKey, "file"]}
                  rules={[{ required: true, message: "Missing file" }]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Key"
                  name={[name, "key"]}
                  fieldKey={[fieldKey, "key"]}
                >
                  <Input placeholder="Access Key" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Day"
                  name={[name, "day"]}
                  fieldKey={[fieldKey, "day"]}
                >
                  <Input placeholder="Day" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Delete"
                  name={[name, "isDelete"]}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
    );
  };

  const initialValues = {
    shares: props.selectedFiles.map((file) => ({
      file: file,
      key: "",
      day: "",
      isDelete: false,
    })),
  };
  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        Modify
      </Button>
      <ModalCollectionForm
        title="Modify Share"
        visible={visible}
        initialValues={initialValues}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default ModShareButton;
