/*
 * @Author: your name
 * @Date: 2021-10-15 15:25:54
 * @LastEditTime: 2021-10-21 11:09:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/newTask/newTask.tsx
 */
import Aria2Task from "./aria2Task";
import BaiduShareTask from "./baiduShareTask";
import RTorrentTask from "./rtorrentTask";
import MegaTask from "./megaTask";
import OnedriveTask from "./onedriveTask";
import GDriveTask from "./gdriveTask";
import AListDownloadTask from "./alistTask";
import {Routers} from "../apiRouter";
import { Col, Row } from 'antd';

interface NewTaskProp {}

const NewTask = (_: NewTaskProp) => {
  const get = (urlString: string, query: any) => {
    let queryStr = new URLSearchParams(query).toString();
    return fetch(`${urlString}?${queryStr}`, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    });
  };

  const post = (urlString: string, data: object, timeoutInMillisecond: number) => {
    let controller = new AbortController();
    setTimeout(() => {
      if (controller) {
        controller.abort();
        console.log('Post request aborted');
      }
    }, timeoutInMillisecond);
    return fetch(urlString, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
      body: JSON.stringify(data),
      signal: controller.signal,
    });
  };
  return (
    <>
    <Row gutter={16}>
      <Col span={8}>
        <Aria2Task post={post} postUrl={Routers.aria2Task}></Aria2Task>
      </Col>
      <Col span={8}>
        <RTorrentTask post={post} postUrl={Routers.rtorrentTask} uploadPostUrl={Routers.rtorrentTaskUploadTorrents}></RTorrentTask>
      </Col>
      <Col span={8}>
        <BaiduShareTask post={post} postUrl={Routers.baiduShareTask} ></BaiduShareTask>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={8}>
        <MegaTask post={post} postUrl={Routers.megaTask}></MegaTask>
      </Col>
      <Col span={8}>
        <OnedriveTask post={post} postUrl={Routers.onedriveTask}></OnedriveTask>
      </Col>
      <Col span={8}>
        <GDriveTask get={get} getUrl={Routers.gdriveAuthURL} post={post} postUrl={Routers.gdriveTask}></GDriveTask>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={8}>
        <AListDownloadTask post={post} postUrl={Routers.alistDownloadTask}></AListDownloadTask>
      </Col>
    </Row>
    </>
  );
};

export default NewTask;
