/*
 * @Author: your name
 * @Date: 2021-10-15 15:51:41
 * @LastEditTime: 2021-10-26 17:29:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/shareManager/shareManager.tsx
 */

import { useState, useRef, useEffect } from "react";
import { Affix, Button, message } from "antd";
import { Routers } from "../apiRouter";
import ShareTable from "./shareTable";
import ModShareBtn from "./modShareBtn";
import DelShareBtn from "./delShareBtn";
import { ShareRow, ShareInfo } from "./interfaces";
import { openInNewTab } from "../utils/utils";

interface ShareManagerProp {}

const ShareManager = (props: ShareManagerProp) => {
  const [shareRows, setShareRows] = useState<ShareRow[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);
  const [sortBy, ] = useState("name");
  const [order, ] = useState(0);
  const shareInfos = useRef<ShareInfo[]>([]);
  const key2ShareInfo = useRef<{ [index: string]: ShareInfo }>({});

  const isInitialMount = useRef(true);

  const post = (urlString: string, data: object) => {
    return fetch(urlString, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
      body: JSON.stringify(data),
    });
  };

  const get = (urlString: string, query: any) => {
    let queryStr = new URLSearchParams(query).toString();
    return fetch(`${urlString}?${queryStr}`, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    });
  };
  const refresh = () => {
    get(Routers.listShares, { sortBy: sortBy, order: order })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          throw new Error("Failed to get shares");
        }
        let { shares } = data;
        setShareRows(
          shares.map((share: any) => ({
            key: share.url,
            name: share.fileName,
            key_: share.key,
            fullPaths: share.fullPaths,
            expire: share.expire,
            totalAccessNumber: share.totalAccessNumber,
            accessNumber: share.accessNumber,
          }))
        );
        shareInfos.current = shares.map((share: any) => ({
          url: share.url,
          fileName: share.fileName,
          fullPaths: share.fullPaths,
          key: share.key,
          expire: share.expire,
          totalAccessNumber: share.totalAccessNumber,
          accessNumber: share.accessNumber,
        }));
        key2ShareInfo.current = {};
        shareInfos.current.forEach((shareInfo: ShareInfo) => {
          key2ShareInfo.current[shareInfo.url] = shareInfo;
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (isInitialMount.current === true) {
      isInitialMount.current = false;
      refresh();
    }
  });

  const deleteShare = (key: React.Key) => {
    post(Routers.deleteShares, {
      urls: [key],
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          message.error(data.err);
        } else {
          message.success(data.msg);
          refresh();
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const deleteSelectedShare = () => {
    post(Routers.deleteShares, {
      urls: selectedUrls,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          message.error(data.err);
        } else {
          message.success(data.msg);
          refresh();
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const handleSelectedShareChange = (keys: React.Key[]) => {
    setSelectedUrls(keys.map((key) => key.toString()));
    setSelectedFiles(keys.map((key) => key2ShareInfo.current[key].fileName));
  };

  const gotoShare = (key: React.Key) => {
    let shareInfo = key2ShareInfo.current[key.toString()];
    let queryStr = "";
    if(shareInfo.key !== "") {
      queryStr = "?" + new URLSearchParams({ key: shareInfo.key }).toString();
    }
    openInNewTab(
      `${process.env.PUBLIC_URL}/share/${shareInfo.url}/${queryStr}`
    );
  };

  return (
    <>
      <Affix offsetTop={10}>
        <div className="ant-btn-group">
          <Button onClick={refresh}>Refresh</Button>
          <ModShareBtn
            refresh={refresh}
            selectedFiles={selectedFiles}
            selectedShareUrls={selectedUrls}
            post={post}
            postUrl={Routers.modShares}
          ></ModShareBtn>
          <DelShareBtn
            selectedFiles={selectedFiles}
            deleteSelectedShare={deleteSelectedShare}
          ></DelShareBtn>
        </div>
      </Affix>
      <ShareTable
        gotoShare={gotoShare}
        rows={shareRows}
        deleteShare={deleteShare}
        onSelectedShareChange={handleSelectedShareChange}
      ></ShareTable>
    </>
  );
};

export default ShareManager;
