/*
 * @Author: your name
 * @Date: 2021-10-19 18:04:54
 * @LastEditTime: 2022-02-01 14:27:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/publicShare/index.tsx
 */

import React from "react";
import FileBreadcrumb from "../file/fileBreadcrumb";
import {Spin, Button, message} from "antd";
import {RouteComponentProps} from "react-router";
import {Routers} from "../apiRouter";
import FileTable from "../file/fileTable";
import {Subtitle} from "../utils/interfaces";
import {openInNewTab, getFileTypeImage} from "../utils/utils";

import Browser from "../file/browser";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane";

import {FileRow, FileInfo, FileBreadcrumbInfo} from "../file/interfaces";

import {MediaType} from "../utils/interfaces";

interface MatchParams {
  url: string;
  path?: string;
}

interface PublicShareProps extends RouteComponentProps<MatchParams> {}

interface PublicShareState {
  pageState: number;
  dir: string;
  selectedFiles: string[];
  selectedKeys: React.Key[];
  fileRows: FileRow[];
  breadcrumbInfos: FileBreadcrumbInfo[];
  preMediaBtnDisabled: boolean;
  nextMediaBtnDisabled: boolean;
  videoJsOptions: {
    autoplay: boolean;
    controls: boolean;
    sources: {src: string; type: string}[];
  };
  browserFiles: string[];
  curMediaType: MediaType;
  mediaUrl: string;
  fileLoading: boolean;
}

class PublicShare extends React.Component<PublicShareProps, PublicShareState> {
  fileKey2FileInfo: {[index: string]: FileInfo};
  constructor(props: PublicShareProps) {
    super(props);
    this.fileKey2FileInfo = {};
    this.state = {
      pageState: -1,
      selectedFiles: [],
      selectedKeys: [],
      fileRows: [],
      dir: "/",
      breadcrumbInfos: [],
      preMediaBtnDisabled: true,
      nextMediaBtnDisabled: true,
      videoJsOptions: {autoplay: false, controls: true, sources: []},
      browserFiles: [],
      curMediaType: MediaType.None,
      mediaUrl: "",
      fileLoading: false,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  get = (urlString: string, query: any) => {
    let queryStr = new URLSearchParams(query).toString();
    queryStr = queryStr !== "" ? `?${queryStr}` : "";
    let fullUrlString = `${urlString}${queryStr}`;
    return fetch(fullUrlString, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    });
  };

  prevLocation: any;
  componentDidUpdate() {
    if (this.props.location !== this.prevLocation) {
      this.prevLocation = this.props.location;
      this.refresh();
    }
  }

  refresh = (gotoDir: string = "") => {
    this.setState({fileLoading: true});
    let key = new URLSearchParams(this.props.location.search).get("key");
    let {url, path} = this.props.match.params;
    if (gotoDir === "") {
      if (!path) path = "/";
      gotoDir = decodeURIComponent(path);
    } else {
      let newHistory = `/share/${url}${gotoDir}${this.props.location.search.toString()}`;
      this.prevLocation = newHistory;
      this.props.history.push(newHistory);
    }
    if (!gotoDir.startsWith("/")) gotoDir = "/" + gotoDir;
    console.log(url, key, gotoDir);
    if (!key) key = "";
    this.get(Routers.listPubShareDirectory, {
      url: url,
      dir: gotoDir,
      key: key,
      sortBy: "name",
      order: "asc",
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            message.error("The directory doesn't exist, please go back");
            if (this.props.history.length <= 2) {
              this.props.history.push(`/share/${url}/${this.props.location.search.toString()}`);
            }
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((json: any) => {
        this.setState({pageState: json.code});
        if (json.code !== 0) return;

        let data = json.directoryInfo;
        if (!json.directoryInfo) throw new Error("Something goes wrong");
        let files = data.files;
        let breadcrumbInfos = data.breadcrumbDirs.map((breadcrumbDir: any) => ({
          name: breadcrumbDir.name,
          path: `${breadcrumbDir.href}/`.split('/').map((s: string) => {return encodeURIComponent(s);}).join('/'),
        }));
        let newState: any = {
          fileRows: files.map((file: FileInfo) => ({
            key: file.path,
            name: file.name,
            size: file.size,
            modifiedTime: file.modTime,
            ModTime: file.ModTime,
            Size: file.Size,
            isDir: file.isDir,
            fileType: file.fileType,
          })),
          breadcrumbInfos: breadcrumbInfos,
          selectedFiles: [],
          selectedKeys: [],
        };
        if (this.state.dir !== gotoDir) {
          newState.dir = gotoDir;
        }
        this.setState(newState);
        window.scrollTo(0, 0);
        this.fileKey2FileInfo = {};
        files.forEach((value: FileInfo) => {
          this.fileKey2FileInfo[value.path] = value;
        });
      })
      .finally(() => {
        this.setState({fileLoading: false});
      });
  };

  getSub = async (file: string) => {
    let {url} = this.props.match.params;
    let subs: void | Subtitle[] = await this.get(
      `${Routers.listShareSubtitles
      }/${url}${file}${this.props.location.search.toString()}`,
      {}
    )
      .then((res) => res.json())
      .then((data) =>
        Object.keys(data.subtitles).map((key: string) => ({
          language: key,
          url: this.fileUrlBuilder(data.subtitles[key]),
        }))
      )
      .catch((reason) => {
        console.error("Error:", reason);
      });
    if (subs === undefined) {
      return [{language: "network error", url: ""}];
    }
    return subs;
  };

  fileUrlBuilder = (filePath: string) => {
    let {url} = this.props.match.params;
    return `${Routers.rawShareFile
      }/${url}${filePath.split("/").map((s: string) => encodeURIComponent(s)).join("/")}${this.props.location.search.toString()}`;
  };

  onFileRowClick = (row: FileRow, _: number | undefined) => {
    let newSelectedFiles = this.state.selectedFiles.filter(
      (value: string) => value !== row.key
    );
    if (newSelectedFiles.length === this.state.selectedFiles.length) {
      newSelectedFiles.push(row.key);
    }
    this.setState({
      selectedFiles: newSelectedFiles,
      selectedKeys: newSelectedFiles,
    });
  };

  onFileRowDoubleClick = (row: FileRow, _: number | undefined) => {
    let fileInfo = this.fileKey2FileInfo[row.key];
    if (fileInfo.isDir) {
      // let {url} = this.props.match.params;
      this.props.history.push(
        `${encodeURIComponent(fileInfo.name)}/${this.props.location.search.toString()}`
      );
    } else {
      openInNewTab(this.fileUrlBuilder(fileInfo.path));
    }
  };

  updateSelectedFiles = (keys: React.Key[]) => {
    this.setState({
      selectedFiles: keys.map((key) => key.toString()),
      selectedKeys: keys,
    });
  };
  render() {
    return (
      <>
        {this.state.pageState === 0 && (
          <SplitPane split="vertical">
            <Pane>
              <div style={{width: "100%", overflow: "auto"}}>
                <FileBreadcrumb
                  fileBreadcrumbInfos={this.state.breadcrumbInfos}
                  goto={this.refresh}
                ></FileBreadcrumb>
                <Button
                  onClick={() => {
                    this.setState({browserFiles: this.state.selectedFiles});
                  }}
                >
                  Browser
                </Button>

                <Spin spinning={this.state.fileLoading} tip="Loading..." size="large">
                  <FileTable
                    getFileTypeImage={getFileTypeImage}
                    onRowClick={this.onFileRowClick}
                    onRowDoubleClick={this.onFileRowDoubleClick}
                    updateSelectedFiles={this.updateSelectedFiles}
                    selectedRowKeys={this.state.selectedKeys}
                    data={this.state.fileRows}
                    fileUrlBuilder={this.fileUrlBuilder}
                  />
                </Spin>
              </div>
            </Pane>
            <Pane>
              <div style={{width: "100%", overflow: "auto"}}>
                <Browser
                  getSub={this.getSub}
                  browserFiles={this.state.browserFiles}
                  fileUrlBuilder={this.fileUrlBuilder}
                />
              </div>
            </Pane>
          </SplitPane>
        )}
        {this.state.pageState === 1 && (
          <p>The share does not exists anymore. Better luck next time :)</p>
        )}
        {this.state.pageState === 2 && (
          <p>Access denied, because of wrong key</p>
        )}
        {(this.state.pageState === 3 || this.state.pageState === 4) && (
          <p>Something goes wrong</p>
        )}
      </>
    );
  }
}

export default PublicShare;
