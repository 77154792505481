/*
 * @Author: your name
 * @Date: 2021-10-12 16:40:59
 * @LastEditTime: 2021-10-26 20:45:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/task/pagination.tsx
 */
import React from "react";

import { Pagination } from "antd";

import { PaginationInfo } from "./interfaces";

interface Props {
  paginationInfo: PaginationInfo;
  onPageChange: (pageNumber: number, pageSize: number | undefined) => void;
}

interface State {}

class MyPagination extends React.Component<Props, State> {
  onChange = (pageNumber: number, pageSize: number | undefined) => {
    this.props.onPageChange(pageNumber, pageSize);
  };

  render() {
    const paginationInfo = this.props.paginationInfo;
    return (
      <>
        <Pagination
          showQuickJumper
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSizeOptions={['5', '10', '20', '50', '100']}
          defaultPageSize={paginationInfo.pageSize}
          defaultCurrent={paginationInfo.currentPage}
          current={paginationInfo.currentPage}
          total={paginationInfo.count}
          onChange={this.onChange}
          disabled={paginationInfo.count === 0}
          showSizeChanger={true}
        />
      </>
    );
  }
}

export default MyPagination;
