/*
 * @Author: your name
 * @Date: 2021-10-15 10:37:48
 * @LastEditTime: 2021-11-02 15:19:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/file/interfaces.tsx
 */
export interface FileInfo {
  path: string;
  name: string;
  size: string;
  Size: number;
  modTime: string;
  ModTime: string;
  isDir: boolean;
  fileType: string;
  accessToken: string;
}

export interface BreadCrumDir {
  href: string;
  name: string;
}

export interface FileRow {
  key: string;
  name: string;
  size: string;
  modifiedTime: string;
  Size: number;
  ModTime: string;
  isDir: boolean;
  fileType: string;
  accessToken: string;
}

export interface HistoryRow {
  key: string;
  name: string;
  dir: string;
  accessTime: string;
}

export interface FileBreadcrumbInfo {
  name: string;
  path: string;
}

export enum FileType {
  Folder = "Folder",
  Picture = "Picture",
  Music = "Music",
  Video = "Video",
  Misc = "Misc",
}
