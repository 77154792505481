/*
 * @Author: your name
 * @Date: 2021-10-14 17:49:29
 * @LastEditTime: 2021-10-23 14:38:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/apiRouter.tsx
 */

export const Routers = {
  login: process.env.REACT_APP_API_URL + "login",

  aria2Task: process.env.REACT_APP_API_URL + "v1/download/aria2",
  rtorrentTask: process.env.REACT_APP_API_URL + "v1/download/rtorrent",
  rtorrentTaskUploadTorrents: process.env.REACT_APP_API_URL + "v1/download/torrent-upload",
  baiduShareTask: process.env.REACT_APP_API_URL + "v1/download/baidu-share",
  megaTask: process.env.REACT_APP_API_URL + "v1/download/mega-share",
  onedriveTask: process.env.REACT_APP_API_URL + "v1/download/onedrive-share",
  gdriveTask: process.env.REACT_APP_API_URL + "v1/download/gdrive-share",
  gdriveAuthURL: process.env.REACT_APP_API_URL + "v1/download/gdrive-share-auth-url",
  alistDownloadTask: process.env.REACT_APP_API_URL + "v1/download/alist-download",

  refreshToken: process.env.REACT_APP_API_URL + "auth/refresh-token",
  rawFile: process.env.REACT_APP_API_URL + "v1/file/raw",
  tempRawFile: process.env.REACT_APP_API_URL + "v1/temp-file/raw",
  listSubtitles: process.env.REACT_APP_API_URL + "v1/file/subtitles",

  getQuota: process.env.REACT_APP_API_URL + "v1/file/quota",
  listDir: process.env.REACT_APP_API_URL + "v1/file/directory",
  deleteHistory:
    process.env.REACT_APP_API_URL + "v1/file/delete-file-access-history",
  cleanHistory:
    process.env.REACT_APP_API_URL + "v1/file/clean-file-access-history",
  listHistory: process.env.REACT_APP_API_URL + "v1/file/file-access-history",

  listTask: process.env.REACT_APP_API_URL + "v1/file/tasks",
  killTask: process.env.REACT_APP_API_URL + "v1/file/kill-task",
  cleanTask:
    process.env.REACT_APP_API_URL + "v1/file/clean-inactive-task-records",

  newDir: process.env.REACT_APP_API_URL + "v1/file/directory",
  archive: process.env.REACT_APP_API_URL + "v1/file/archive",
  extract: process.env.REACT_APP_API_URL + "v1/file/extract",
  delete: process.env.REACT_APP_API_URL + "v1/file/delete",
  convert: process.env.REACT_APP_API_URL + "v1/file/convert",
  segment: process.env.REACT_APP_API_URL + "v1/file/segment",
  split: process.env.REACT_APP_API_URL + "v1/file/split-track",
  moveTo: process.env.REACT_APP_API_URL + "v1/file/move",
  sync: process.env.REACT_APP_API_URL + "v1/file/rclone",
  syncMountPoint: process.env.REACT_APP_API_URL + "v1/file/rclone-mount-point",
  genPlaylist: process.env.REACT_APP_API_URL + "v1/file/gen-playlist",

  share: process.env.REACT_APP_API_URL + "v1/share/create-shares",
  listShares: process.env.REACT_APP_API_URL + "v1/share/shares",
  deleteShares: process.env.REACT_APP_API_URL + "v1/share/del-shares",
  modShares: process.env.REACT_APP_API_URL + "v1/share/mod-shares",

  videoProgress: process.env.REACT_APP_API_URL + "v1/file/video-progress",

  upload: process.env.REACT_APP_API_URL + "v1/file/upload",

  listPubShareDirectory:
    process.env.REACT_APP_API_URL + "v1/public-share/directory",
  rawShareFile: process.env.REACT_APP_API_URL + "v1/public-share/raw",
  listShareSubtitles:
    process.env.REACT_APP_API_URL + "v1/public-share/subtitles",

  getVidSubPairInDir: process.env.REACT_APP_API_URL + "v1/file/vid-sub-pair-in-dir",
  embedSub:  process.env.REACT_APP_API_URL + "v1/file/embed-sub",

  unwrapDirectory:  process.env.REACT_APP_API_URL + "v1/file/unwrap-directory",
};
