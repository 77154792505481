import { Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

interface DeleteButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const DeleteButton = (props: DeleteButtonProps) => {

  const delete_ = async (files: string[]) => {
    const response = await props.post(props.postUrl, {
      fileList: files, 
    });

    if(!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if(json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
  }

  const path = require('path');
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete these files?',
      icon: <ExclamationCircleOutlined />,
      content: props.selectedFiles.map(file => path.basename(file)).reduce((previousValue, currentValue) => `${previousValue}, ${currentValue}`),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        delete_(props.selectedFiles);
      },
    });
  }

  return (
    <>
      <Button onClick={showDeleteConfirm} type="dashed">
        Delete
      </Button>
    </>
  );
};

export default DeleteButton;
