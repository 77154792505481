/*
 * @Author: your name
 * @Date: 2021-10-15 11:45:50
 * @LastEditTime: 2021-10-15 15:39:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 */

import {Form, Input, Button, Image, message} from 'antd';
import {useState, useEffect} from 'react';

interface GDriveTaskProp {
  get: (url: string, query: any) => Promise<Response>;
  getUrl: string;
  post: (url: string, data: any, timeoutInMillisecond: number) => Promise<Response>;
  postUrl: string;
}

const GDriveTaskMsgKey = 'GDriveTaskMsgKey';

const GDriveTask = (props: GDriveTaskProp) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [authURL, setAuthURL] = useState("");
  const timeoutInSec = 10;

  useEffect(() => {
    getAuthURL();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAuthURL = () => {
    const {get, getUrl} = props;
    get(getUrl, null).then((response) => {
      if (!response.ok) {
        throw new Error("HTTP request error");
      }
      return response.json();
    }).then((data: any) => {
      if (data.code !== 0) {
        message.error({content: data.err, key: GDriveTaskMsgKey});
      } else {
        setAuthURL(data.auth_url);
      }
    }).catch((err) => {
      message.error({content: err.message, key: GDriveTaskMsgKey});
    });
  }
  const handleFormSubmit = (value: any) => {
    setBtnDisabled(true);
    message.loading({content: "Starting GDrive task...", key: GDriveTaskMsgKey, duration: timeoutInSec});
    const {post, postUrl} = props;
    post(postUrl, {
      fileId: value.fileId,
      authCode: value.authCode,
    }, timeoutInSec * 1000)
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          message.error({content: data.err, key: GDriveTaskMsgKey});
        } else {
          message.success({content: data.msg, key: GDriveTaskMsgKey});
        }
      })
      .catch((err) => {
        message.error({content: err.message, key: GDriveTaskMsgKey});
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };
  return (
    <>
      <Image></Image>
      <h3>GDrive Share Task</h3>
      <Form
        name="gdriveNewTask"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={handleFormSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="File Id"
          name="fileId"
          rules={[{required: true, message: "Please input file id"}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Auth Code"
          name="authCode"
          rules={[{required: false}]}
        >
          <Input />
        </Form.Item>
        <a href={authURL} target="_blank" rel="noopener noreferrer">Get auth code if needed(first time)</a>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit" disabled={btnDisabled}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default GDriveTask;
