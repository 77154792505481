import videojs from "video.js";
import React from "react";
import {useState, useRef, useEffect, useCallback} from "react";
import {Button, Image, Modal} from "antd";
import {getMediaTypeByFileName} from "../utils/utils";
import {PlaylistItem, MediaType} from "../utils/interfaces";
import VideoPlayer from "../utils/videojsPlayer";
import {Subtitle} from "../utils/interfaces";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import MusicPlayer from "../utils/musicPlayer";
const {confirm} = Modal;

class Media {
  sources: string[];
  paths: string[];
  type: MediaType;
  constructor(sources: string[], paths: string[], type: MediaType) {
    this.sources = sources;
    this.paths = paths;
    this.type = type;
  }
}

class Images extends Media {}
class Videos extends Media {}
class Music extends Media {}

interface BrowserProps {
  browserFiles: string[];
  onPlayerReady?: (player: videojs.Player, btns: videojs.Button[]) => void;
  onVideoProgress?: (
    videoSrc: string,
    currentTime: number,
    duration: number
  ) => void;
  fileUrlBuilder: (file: string, record: boolean) => string;
  getVideoProgress?: (
    videoSrcUrl: string
  ) => Promise<{hasProgress: boolean; progress: number}>;
  getSub?: (file: string) => Promise<{language: string; url: string}[]>;
}

const defaultVideoJsOptions = {autoplay: false, controls: true, sources: [], playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]};

const Browser = (props: BrowserProps) => {
  const [videoJsOptions, setVideoJsOptions] = useState<videojs.PlayerOptions>(defaultVideoJsOptions);
  const [curMediaType, setCurMediaType] = useState(MediaType.None);
  const [videoPlaylist, setVideoPlaylist] = useState<PlaylistItem[]>([]);
  const [musicPlaylist, setMusicPlaylist] = useState<PlaylistItem[]>([]);
  const [preBtnDisabled, setPreBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [imgGroupVisible, setImgGroupVisible] = useState(false);

  const player = useRef<videojs.Player>();
  const subOffsetBtns = useRef<videojs.Button[]>();

  const medias = useRef<Media[]>([]);
  const currentIndex = useRef(0);

  const [imageGroup, setImageGroup] = useState(<></>);
  const [imagePreviewSrc, setImagePreviewSrc] = useState("");

  const mediaBtnVisibleCheck = useCallback(
    (index: number) => {
      if ((index <= 0) !== preBtnDisabled) setPreBtnDisabled(index <= 0);
      let mediasLength = medias.current ? medias.current.length : 0;
      if ((index >= mediasLength - 1) !== nextBtnDisabled)
        setNextBtnDisabled(index >= mediasLength - 1);
    }, [preBtnDisabled, nextBtnDisabled]);

  const imagePrepare = useCallback(
    () => {
      if (
        !medias.current ||
        !medias.current[currentIndex.current] ||
        medias.current[currentIndex.current].type !== MediaType.Image
      )
        return;
      if (curMediaType !== medias.current[currentIndex.current].type) {
        setCurMediaType(medias.current[currentIndex.current].type);
      }
      setImagePreviewSrc(medias.current[currentIndex.current].sources[0]);
      setImageGroup(
        <>
          {medias.current[currentIndex.current].sources.map((source: string) => (
            <Image loading="lazy" src={source} />
          ))}
        </>
      );
    }, [curMediaType]);

  const videoPrepare = useCallback(
    () => {
      let medias_ = medias.current;
      let currentIndex_ = currentIndex.current;
      if (
        !medias_ ||
        !medias_[currentIndex_] ||
        medias_[currentIndex_].type !== MediaType.Video
      )
        return;
      let currentMedia = medias_[currentIndex_];
      if (curMediaType !== currentMedia.type) {
        setCurMediaType(currentMedia.type);
      }
      let newVideoJsOptions = defaultVideoJsOptions;
      setVideoPlaylist(
        currentMedia.sources.map((source: string, index: number) => ({
          key: currentMedia.paths[index],
          src: source,
          type: "video/mp4",
        }))
      );
      setVideoJsOptions(newVideoJsOptions);
    }, [curMediaType]);

  const musicPrepare = useCallback(
    () => {
      let medias_ = medias.current;
      let currentIndex_ = currentIndex.current;
      if (
        !medias_ ||
        !medias_[currentIndex_] ||
        medias_[currentIndex_].type !== MediaType.Music
      )
        return;
      let currentMedia = medias_[currentIndex_];
      if (curMediaType !== currentMedia.type) {
        setCurMediaType(currentMedia.type);
      }
      setMusicPlaylist(
        currentMedia.sources.map((source: string, index: number) => ({
          key: currentMedia.paths[index],
          src: source,
          type: "",
        }))
      );
    }, [curMediaType]);

  const mediaPrepare = useCallback(
    () => {
      imagePrepare();
      videoPrepare();
      musicPrepare();
    }, [imagePrepare, videoPrepare, musicPrepare]);

  const splitMedia = useCallback(
    () => {
      const mediaUrlBuilder = props.fileUrlBuilder;
      medias.current = [];
      let lastAddedFileType = MediaType.None;
      let videos: Videos;
      let images: Images;
      let music: Music;
      let media: Media | undefined = undefined;
      props.browserFiles.forEach(value => {
        let url = mediaUrlBuilder(value, true);
        let fileType = getMediaTypeByFileName(value);
        switch (fileType) {
          case MediaType.Video:
            if (lastAddedFileType !== fileType) {
              videos = new Videos([], [], fileType);
              if (lastAddedFileType !== MediaType.None) {
                medias.current && media && medias.current.push(media);
              }
              lastAddedFileType = fileType;
              media = videos;
            }
            videos.sources.push(url);
            videos.paths.push(value);
            break;
          case MediaType.Image:
            if (lastAddedFileType !== fileType) {
              images = new Images([], [], fileType);
              if (lastAddedFileType !== MediaType.None) {
                medias.current && media && medias.current.push(media);
              }
              lastAddedFileType = fileType;
              media = images;
            }
            images.sources.push(url);
            images.paths.push(value);
            break;
          case MediaType.Music:
            if (lastAddedFileType !== fileType) {
              music = new Music([], [], fileType);
              if (lastAddedFileType !== MediaType.None) {
                medias.current && media && medias.current.push(media);
              }
              lastAddedFileType = fileType;
              media = music;
            }
            music.sources.push(url);
            music.paths.push(value);
            break;
        }
      });
      if (lastAddedFileType !== MediaType.None && media !== undefined) {
        medias.current && medias.current.push(media);
      }
    }, [props.browserFiles, props.fileUrlBuilder]);

  useEffect(() => {
    currentIndex.current = 0;
    splitMedia();
    mediaPrepare(); // Update state
    mediaBtnVisibleCheck(0); // Update state
  }, [props.browserFiles, mediaBtnVisibleCheck, mediaPrepare, splitMedia]);

  const shouldResume = (videoUrl: string) => {
    if (
      curMediaType !== MediaType.Video ||
      !player.current ||
      !props.getVideoProgress
    )
      return;
    let player_ = player.current;
    props.getVideoProgress(videoUrl).then((value) => {
      if (value.hasProgress) {
        confirm({
          title: `Resume`,
          icon: <ExclamationCircleOutlined />,
          content: `Resume video at ${new Date(value.progress * 1000)
            .toISOString()
            .substr(11, 8)} ?`,
          onOk() {
            player_.currentTime(value.progress);
          },
        });
      }
    });
  };

  const hasSubtitles = (fileName: string) => {
    let player_: videojs.Player;
    const {getSub} = props;
    if (curMediaType !== MediaType.Video || !player.current || !getSub) {
      return;
    }
    player_ = player.current;
    getSub(fileName).then((subtitles: Subtitle[]) => {
      if (subtitles.length === 0) {
        subOffsetBtns.current &&
          subOffsetBtns.current.forEach((btn) => {
            btn.hide();
          });
        return;
      }
      player_.addRemoteTextTrack &&
        subtitles.forEach((subtitle) => {
          player_.addRemoteTextTrack(
            {
              kind: "subtitles",
              src: subtitle.url,
              srclang: "zh",
              label: subtitle.language,
            },
            false
          );
        });
      subOffsetBtns.current &&
        subOffsetBtns.current.forEach((btn) => {
          btn.show();
        });
    });
  };

  const hasChapter = (chapterFileUrl: string) => {
    let player_: videojs.Player;
    if (curMediaType !== MediaType.Video || !player.current) {
      return;
    }
    player_ = player.current;
    player_.addRemoteTextTrack &&
      player_.addRemoteTextTrack(
        {
          kind: "chapters",
          default: true,
          src: chapterFileUrl,
        },
        false
      );
  };

  const handlePlayerReady = (
    player_: videojs.Player,
    btns: videojs.Button[]
  ) => {
    const {onPlayerReady, onVideoProgress} = props;
    player.current = player_;
    subOffsetBtns.current = btns;
    mediaPrepare();
    // you can handle player events here
    player_.on("timeupdate", () => {
      onVideoProgress &&
        onVideoProgress(
          player_.currentSrc(),
          player_.currentTime(),
          player_.duration()
        );
    });
    player_.on("waiting", () => {
      console.log("player is waiting");
    });

    player_.on("dispose", () => {
      console.log("player will dispose");
    });

    onPlayerReady && onPlayerReady(player_, btns);
  };

  const goPreviousVisual = () => {
    currentIndex.current--;
    mediaBtnVisibleCheck(currentIndex.current);
    mediaPrepare();
  };

  const goNextVisual = () => {
    currentIndex.current++;
    mediaBtnVisibleCheck(currentIndex.current);
    mediaPrepare();
  };

  const handlePlaylistItemChange = (item: PlaylistItem) => {
    shouldResume(item.src);
    hasSubtitles(item.key);
    hasChapter(props.fileUrlBuilder(item.key + ".chapter.vtt", false));
  };

  const path = require("path");
  return (
    <>
      <div>
        <Button disabled={preBtnDisabled} onClick={goPreviousVisual}>
          Previous
        </Button>
        <Button disabled={nextBtnDisabled} onClick={goNextVisual}>
          Next
        </Button>
      </div>

      {(medias.current &&
        medias.current.length > 0 &&
        curMediaType !== MediaType.None && (
          <h2>{`[${currentIndex.current + 1} / ${medias.current.length
            }] ${path.basename(
              medias.current[currentIndex.current].paths[0]
            )}`}</h2>
        )) || <h2>No media found</h2>}

      {curMediaType === MediaType.Image && (
        <>
          <Image
            preview={{visible: false}}
            width={200}
            src={imagePreviewSrc}
            onClick={() => setImgGroupVisible(true)}
          />
          <div style={{display: "none"}}>
            <Image.PreviewGroup
              preview={{
                visible: imgGroupVisible,
                onVisibleChange: (vis) => setImgGroupVisible(vis),
              }}
            >
              {imageGroup}
            </Image.PreviewGroup>
          </div>
        </>
      )}

      {curMediaType === MediaType.Video && (
        <VideoPlayer
          playlist={videoPlaylist}
          onPlaylistItemChange={handlePlaylistItemChange}
          options={videoJsOptions}
          onReady={handlePlayerReady}
        />
      )}

      {curMediaType === MediaType.Music && (
        <MusicPlayer playlist={musicPlaylist} />
      )}
    </>
  );
};

export default React.memo(Browser);
