/*
 * @Author: your name
 * @Date: 2021-10-13 14:30:32
 * @LastEditTime: 2021-10-27 10:48:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/utils/modalCollectionForm.tsx
 */
import React from "react";
import { Modal, Form } from "antd";
interface ModalCollectionFormProps {
  visible: boolean;
  title: string;
  initialValues: object;
  confirmLoading: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  formItems: () => any;
}

const ModalCollectionForm: React.FC<ModalCollectionFormProps> = (
  props: ModalCollectionFormProps
) => {
  const [form] = Form.useForm();
  return (
    <Modal
      width={1200}
      visible={props.visible}
      title={props.title}
      okText="Submit"
      cancelText="Cancel"
      onCancel={props.onCancel}
      confirmLoading={props.confirmLoading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            props.onSubmit(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        style={{ width: "100%", overflow: "auto" }}
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={props.initialValues}
      >
        {" "}
        {props.formItems()}
      </Form>
    </Modal>
  );
};

export default ModalCollectionForm;
