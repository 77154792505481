/*
 * @Author: your name
 * @Date: 2021-10-18 15:15:36
 * @LastEditTime: 2021-10-18 17:08:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/file/buttons/upload.tsx
 */

import { useState } from "react";
import { Drawer, Button, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
const { Dragger } = Upload;

interface UploadButtonProps {
  post: (url: string, headers: any, data: any) => Promise<Response>;
  uploadPostUrl: string;
}

const UploadButton = (props: UploadButtonProps) => {
  const [drawVisible, setDrawVisible] = useState(false);

  const showUploadDrawer = () => {
    setDrawVisible(true);
  };

  const onDrawClose = () => {
    setDrawVisible(false);
  };

  return (
    <>
      <Button onClick={showUploadDrawer}>Upload</Button>
      <Drawer
        title="File Upload"
        placement="right"
        onClose={onDrawClose}
        visible={drawVisible}
      >
        <Dragger name="files" action={props.uploadPostUrl} multiple={true}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag files to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload.
          </p>
        </Dragger>
      </Drawer>
    </>
  );
};

export default UploadButton;
