import { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import ModalCollectionForm from './../../utils/modalCollectionForm';

interface NewDirectoryButtonProps {
    dir: string;
    postUrl: string;
    post: (url: string, data: any) => Promise<Response>;
}

const NewDirectoryButton = (props: NewDirectoryButtonProps) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleSubmit = async (values: any) => {
        setConfirmLoading(true);

        const response = await props.post(props.postUrl, {
            curPath: props.dir,
            dirName: values.dirName,
        });

        if (!response.ok) {
            message.error("HTTP request error");
        } else {
            let json = await response.json();
            if (json.code !== 0) {
                message.error(`${json.msg}: ${json.err}`);
            } else {
                message.success(json.msg);
            }
        }
        setConfirmLoading(false);
        setVisible(false);
    }

    const formItems = () => {
        return (
            <>
                <Form.Item name="dirName" label="Directory Name" >
                    <Input />
                </Form.Item>
            </>
        )
    }

    return (
        <>
            <Button
                onClick={() => {
                    setVisible(true);
                }}
            >
                New Directory
            </Button>
            <ModalCollectionForm
                title="New Directory"
                visible={visible}
                initialValues={{}}
                confirmLoading={confirmLoading}
                onCancel={() => {
                    setVisible(false);
                }}
                onSubmit={handleSubmit}
                formItems={formItems}
            />
        </>
    );
};

export default NewDirectoryButton;
