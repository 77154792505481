/*
 * @Author: your name
 * @Date: 2021-10-12 14:17:28
 * @LastEditTime: 2021-10-26 20:44:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/file/operateButtonGroup.tsx
 */
import React from "react";
import { Button } from "antd";
import FileOperateButtonGroup from "./fileOperateButtonGroup";
import HistoryButton from "./buttons/history";
import NewDirectoryButton from "./buttons/newDirectory";
import { HistoryRow } from "./interfaces";
import { PaginationInfo } from "../utils/interfaces";
import { Routers } from "../apiRouter";
import UploadButton from "./buttons/upload";

interface Props {
  get: (url: string, query: any) => Promise<Response>;
  post: (url: string, data: object) => Promise<Response>;
  postRaw: (url: string, headers: any, data: any) => Promise<Response>;
  refresh: (gotoDir?: string, locatedName?: string) => void;
  selectedFiles: string[];
  curDir: string;
  onBrowserFilesChange: (files: string[]) => void;
  deleteHistory: (key: React.Key) => void;
  locateHistory: (dir: string, name: string) => void;
  refreshHistory: () => void;
  cleanHistory: () => void;
  historyRows: HistoryRow[];
  paginationInfo: PaginationInfo;
  onPageChange: (pageNumber: number, pageSize: number | undefined) => void;
}

interface State {}

class OperateButtonGroup extends React.Component<Props, State> {
  uploadUrlBuilder() {
    let queryStr = new URLSearchParams({
      curPath: this.props.curDir,
    }).toString();
    return `${Routers.upload}?${queryStr}`;
  }

  render() {
    return (
      <div style={{ width: "100%", overflow: "auto" }}>
        <Button type="primary" onClick={() => this.props.refresh()}>
          Refresh
        </Button>
        <HistoryButton
          paginationInfo={this.props.paginationInfo}
          onPageChange={this.props.onPageChange}
          cleanHistory={this.props.cleanHistory}
          refreshHistory={this.props.refreshHistory}
          rows={this.props.historyRows}
          deleteHistory={this.props.deleteHistory}
          locateHistory={this.props.locateHistory}
        ></HistoryButton>

        <div className="ant-btn-group">
          <NewDirectoryButton
            dir={this.props.curDir}
            post={this.props.post}
            postUrl={Routers.newDir}
          ></NewDirectoryButton>
          <UploadButton
            post={this.props.postRaw}
            uploadPostUrl={this.uploadUrlBuilder()}
          ></UploadButton>
        </div>
        <br />

        {(this.props.selectedFiles.length > 0 && (
          <FileOperateButtonGroup
            post={this.props.post}
            get={this.props.get}
            selectedFiles={this.props.selectedFiles}
            curDir={this.props.curDir}
            onBrowserFilesChange={this.props.onBrowserFilesChange}
          />
        )) || (
          <>
            <div> Select files to see operation buttons or to copy/cut </div>
          </>
        )}
        <br />
      </div>
    );
  }
}

export default OperateButtonGroup;
