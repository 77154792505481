/*
 * @Author: your name
 * @Date: 2021-10-15 10:37:48
 * @LastEditTime: 2021-10-21 16:03:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/file/buttons/history.tsx
 */
import React, {useState} from "react";
import {Button, Modal, Space, Table, Popconfirm} from "antd";
import '../../utils/common.css';
import {HistoryRow} from "../interfaces";
import MyPagination from "../../utils/pagination";
import {PaginationInfo} from "../../utils/interfaces";
import { getAntdTableSelections } from "../../utils/utils";

interface HistoryButtonProps {
  rows: HistoryRow[];
  deleteHistory: (key: React.Key) => void;
  locateHistory: (dirname: string, basename: string) => void;
  refreshHistory: () => void;
  cleanHistory: () => void;
  paginationInfo: PaginationInfo;
  onPageChange: (pageNumber: number, pageSize: number | undefined) => void;
}

const HistoryButton = (props: HistoryButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  
  const showModal = () => {
    setVisible(true);
    props.refreshHistory();
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Parent Directory",
      dataIndex: "dir",
    },
    {
      title: "Access Time",
      dataIndex: "accessTime",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: {key: React.Key, name: string, dir: string}) =>
        props.rows.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => props.deleteHistory(record.key)}
            >
              <button type="button" className="button-like-a-link">Delete</button>
            </Popconfirm>
            <button type="button" className="button-like-a-link"
              onClick={(e) => {
                e.preventDefault();
                props.locateHistory(record.dir, record.name);
              }}
            >
              Goto
            </button>
          </Space>
        ) : null,
    },
  ];
  
  const onSelectChange = (selectedRowKeys_: React.Key[], _selectedRows: any[]) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys_);
    setSelectedRowKeys(selectedRowKeys_);
  };

  const rowSelection = {
      selectedRowKeys,
      selections: getAntdTableSelections(setSelectedRowKeys, selectedRowKeys),
      onChange: onSelectChange,
    };
  return (
    <>
      <Button onClick={showModal}>History</Button>
      <Modal
        title="History"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Table
          columns={columns}
          dataSource={props.rows}
          pagination={false}
          style={{ width: "100%", overflow: "auto" }}
          rowSelection={rowSelection}
        ></Table>
        <MyPagination
          paginationInfo={props.paginationInfo}
          onPageChange={props.onPageChange}
        ></MyPagination>
        <Button onClick={props.refreshHistory}>Refresh</Button>
        <Button onClick={props.cleanHistory}>Clean</Button>
      </Modal>
    </>
  );
};

export default HistoryButton;
