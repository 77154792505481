/*
 * @Author: your name
 * @Date: 2021-10-15 20:46:08
 * @LastEditTime: 2021-11-02 11:41:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/utils/utils.tsx
 */
import { Table } from "antd";
import { useState } from "react";
import { MediaType } from "./interfaces";
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const useForceUpdate = () => {
  const [, setState] = useState<any>();
  return () => setState({});
};

export const getFileTypeImage = (fileType: string) => {
  return `${process.env.PUBLIC_URL}/${fileType}.png`;
};

export const getAntdTableSelections = (
  updateSelectedRows: (keys: React.Key[]) => void,
  selectedRowKeys?: React.Key[]
) => {
  return [
    Table.SELECTION_ALL,
    Table.SELECTION_INVERT,
    Table.SELECTION_NONE,
    {
      key: "odd",
      text: "Select Odd Row",
      onSelect: (changeableRowKeys: React.Key[]) => {
        let newSelectedRowKeys = [];
        newSelectedRowKeys = changeableRowKeys.filter(
          (_, index) => index % 2 === 0
        );
        updateSelectedRows(newSelectedRowKeys);
      },
    },
    {
      key: "even",
      text: "Select Even Row",
      onSelect: (changeableRowKeys: React.Key[]) => {
        let newSelectedRowKeys = [];
        newSelectedRowKeys = changeableRowKeys.filter(
          (_, index) => index % 2 !== 0
        );
        updateSelectedRows(newSelectedRowKeys);
      },
    },
    {
      key: "range",
      text: "Select Range Row",
      onSelect: (changeableRowKeys: React.Key[]) => {
        let newSelectedRowKeys = [];
        let shouldSelect = false;
        let setOfSelectedRowKeys = new Set();
        if (!selectedRowKeys) return;
        for (let selectedRowKey of selectedRowKeys) {
          setOfSelectedRowKeys.add(selectedRowKey);
        }
        for (let changeableRowKey of changeableRowKeys) {
          if (setOfSelectedRowKeys.has(changeableRowKey) || shouldSelect) {
            newSelectedRowKeys.push(changeableRowKey);
            shouldSelect =
              !shouldSelect || !setOfSelectedRowKeys.has(changeableRowKey);
          }
        }
        updateSelectedRows(newSelectedRowKeys);
      },
    },
  ];
};

export const getMediaTypeByFileName = (fileName: string) => {
  const path = require("path");
  let ext = path.extname(fileName).toLowerCase();
  let ext2MediaType = new Map([
    [".mp4", MediaType.Video],
    [".webm", MediaType.Video],

    [".jpg", MediaType.Image],
    [".jpeg", MediaType.Image],
    [".gif", MediaType.Image],
    [".png", MediaType.Image],
    [".svg", MediaType.Image],
    [".webp", MediaType.Image],
    [".ico", MediaType.Image],
    [".bmp", MediaType.Image],

    [".ogg", MediaType.Music],
    [".flac", MediaType.Music],
    [".mp3", MediaType.Music],
    [".ape", MediaType.Music],
    [".aac", MediaType.Music],
    [".wma", MediaType.Music],
    [".wav", MediaType.Music],
  ]);
  if (!ext2MediaType.has(ext)) return MediaType.None;
  return ext2MediaType.get(ext);
};
