import {Form, Button, Input, Image, message, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import {RcFile, UploadChangeParam, UploadFile} from 'antd/lib/upload/interface';
import {useState} from 'react';

const {TextArea} = Input;
interface RTorrentTaskProp {
  post: (url: string, data: any, timeoutInMillisecond: number) => Promise<Response>;
  postUrl: string;
  uploadPostUrl: string;
}

const RTorrentTaskMsgKey = 'RTorrentTaskMsgKey';
const RTorrentUploadTaskMsgKey = 'RTorrentUploadTaskMsgKey';

const RTorrentTask = (props: RTorrentTaskProp) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const timeoutInSec = 10;
  const handleFormSubmit = (value: any) => {
    setBtnDisabled(true);
    message.loading({content: "Starting RTorrentTask...", key: RTorrentTaskMsgKey, duration: timeoutInSec});
    const {post, postUrl} = props;
    post(postUrl, {
      url: value.url,
      cookies: value.cookies,
    }, timeoutInSec * 1000)
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          message.error({content: data.err, key: RTorrentTaskMsgKey});
        } else {
          message.success({content: data.msg, key: RTorrentTaskMsgKey});
        }
      })
      .catch((err) => {
        message.error({content: err.message, key: RTorrentTaskMsgKey});
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };

  const Uploader = () => {
    const acceptFileType = ".torrent";
    const {uploadPostUrl} = props;
    const props_ = {
      name: "files",
      action: uploadPostUrl,
      accept: acceptFileType,
      beforeUpload: (file: RcFile, _fileList: RcFile[]) => {
        const isTorrent = file.name.endsWith(acceptFileType);
        if (!isTorrent) {
          message.error({content: `${file.name} is not a torrent file`, key: RTorrentUploadTaskMsgKey});
        }
        return isTorrent || Upload.LIST_IGNORE;
      },
      onChange: (info: UploadChangeParam<UploadFile<RcFile>>) => {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success({content: `${info.file.name} uploaded successfully`, key: RTorrentUploadTaskMsgKey});
        } else if (info.file.status === 'error') {
          message.error({content: `${info.file.name} upload failed.`, key: RTorrentUploadTaskMsgKey});
        }
      },
      multiple: true,
    };
    return (
      <Upload {...props_}>
        <Button icon={<UploadOutlined />}>Upload torrents</Button>
      </Upload>
    );
  };

  return (
    <>
      <Image></Image>
      <h3>RTorrent Task</h3>
      <Form
        name="rtorrentNewTask"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={handleFormSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Url"
          name="url"
          rules={[{required: true, message: "Please input url"}]}
        >
          <TextArea rows={10} placeholder="Support multiple-line urls or titles(searched on sukebei)" />
        </Form.Item>
        <Form.Item label="Cookies" name="cookies">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit" disabled={btnDisabled}>
            Submit
          </Button>
          <Uploader />
        </Form.Item>
      </Form>
    </>
  );
};

export default RTorrentTask;
