/*
 * @Author: your name
 * @Date: 2021-10-15 10:37:48
 * @LastEditTime: 2021-10-27 16:55:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/App.tsx
 */
import "./App.css";
import { useEffect, useState } from "react";
import LoginForm from "./login/login";
import FileManager from "./file/fileManager";
import Task from "./task/task";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { checkRefreshToken } from "./loginToken";
import { Tabs, BackTop, message } from "antd";
import NewTask from "./newTask/newTask";
import ShareManager from "./shareManager/shareManager";
import PublicShare from "./publicShare";
import {
  PlusCircleOutlined,
  FileOutlined,
  UnorderedListOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

message.config({
  top: 50,
});

const { TabPane } = Tabs;
type Theme = "light" | "dark";

const stylesheets = {
  light: "https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.min.css",
  dark: "https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.dark.min.css",
};

const createStylesheetLink = (): HTMLLinkElement => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.id = "antd-stylesheet";
  document.head.appendChild(link);
  return link;
};

const getStylesheetLink = (): HTMLLinkElement =>
  document.head.querySelector("#antd-stylesheet") || createStylesheetLink();

const systemTheme = () =>
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

const getTheme = () => systemTheme();

const setTheme = (t: Theme) => {
  getStylesheetLink().href = stylesheets[t];
};

// const toggleTheme = () => setTheme(getTheme() === "dark" ? "light" : "dark");

function App() {
  useEffect(() => setTheme(getTheme()), []);
  const [loginState, setLoginState] = useState(0);
  const [currentActiveTab, setCurrentActiveTab] = useState("");
  checkRefreshToken().then((isLogin: boolean) => {
    if (isLogin) {
      setLoginState(2);
    } else {
      setLoginState(1);
    }
  });

  const tabActiveChange = (value: string) => {
    setCurrentActiveTab(value);
  };

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/share/:url/:path*" component={PublicShare} />
          <Route path="/">
            {loginState === 1 && <LoginForm />}
            {loginState === 2 && (
              <Tabs
                defaultActiveKey="files"
                tabPosition="top"
                centered
                onChange={tabActiveChange}
              >
                <TabPane
                  tab={
                    <span>
                      <PlusCircleOutlined />
                      New Task
                    </span>
                  }
                  key="newTask"
                >
                  <BackTop />
                  <NewTask />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <FileOutlined />
                      Files
                    </span>
                  }
                  key="files"
                >
                  <BackTop />
                  <Switch>
                    <Route path="/fileManager**" component={FileManager} />
                    <Route path="/" exact={true} strict={true}>
                      <Redirect to="/fileManager/"></Redirect>
                    </Route>
                  </Switch>
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <UnorderedListOutlined />
                      Tasks
                    </span>
                  }
                  key="tasks"
                >
                  <BackTop />
                  <Task currentActiveTab={currentActiveTab} />
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      <ShareAltOutlined />
                      Shares
                    </span>
                  }
                  key="shares"
                >
                  <BackTop />
                  <ShareManager />
                </TabPane>
              </Tabs>
            )}
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
