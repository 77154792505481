/*
 * @Author: your name
 * @Date: 2021-10-15 11:45:50
 * @LastEditTime: 2021-10-15 15:39:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 */

import {Form, Input, Button, Image, message} from 'antd';
import {useState} from 'react';

interface MegaTaskProp {
  post: (url: string, data: any, timeoutInMillisecond: number) => Promise<Response>;
  postUrl: string;
}

const MegaTaskMsgKey = 'MegaTaskMsgKey';

const MegaTask = (props: MegaTaskProp) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const timeoutInSec = 10;
  const handleFormSubmit = (value: any) => {
    setBtnDisabled(true);
    message.loading({content: "Starting Mega task...", key: MegaTaskMsgKey, duration: timeoutInSec});
    const {post, postUrl} = props;
    post(postUrl, {
      shareUrl: value.shareUrl,
    }, timeoutInSec * 1000)
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP request error");
        }
        return response.json();
      })
      .then((data: any) => {
        if (data.code !== 0) {
          message.error({content: data.err, key: MegaTaskMsgKey});
        } else {
          message.success({content: data.msg, key: MegaTaskMsgKey});
        }
      })
      .catch((err) => {
        message.error({content: err.message, key: MegaTaskMsgKey});
      })
      .finally(() => {
        setBtnDisabled(false);
      });
  };
  return (
    <>
      <Image></Image>
      <h3>Mega Share Task</h3>
      <Form
        name="megaNewTask"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={handleFormSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Share Url"
          name="shareUrl"
          rules={[{required: true, message: "Please input share url"}]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit" disabled={btnDisabled}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MegaTask;
