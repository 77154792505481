/*
 * @Author: your name
 * @Date: 2021-10-14 15:48:40
 * @LastEditTime: 2021-11-02 11:22:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/interfaces.tsx
 */
export interface PaginationInfo {
  count: number;
  pageSize: number;
  totalPage: number;
  currentPage: number;
  isFirstPage: boolean;
  isLastPage: boolean;
}

export interface PlaylistItem {
  key: string;
  src: string;
  type: string;
}

export interface Subtitle {
  language: string;
  url: string;
}

export enum MediaType {
  None,
  Video,
  Image,
  Music,
}
