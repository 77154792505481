import React from "react";
import OperateButtonGroup from "./operateButtonGroup";
import TaskTable from "./taskTable";
import MyPagination from "../utils/pagination";
import { PaginationInfo } from "../utils/interfaces";
import { Row } from "./interfaces";
import { Routers } from "../apiRouter";
import { Affix, Switch } from "antd";

interface Props {
  currentActiveTab: string;
}
interface State {
  rows: Row[];
  paginationInfo: PaginationInfo;
  selectedTaskIds: string[];
}

class Task extends React.Component<Props, State> {
  curPage: number = 1;
  pageSize: number = 5;
  state: State = {
    rows: [],
    paginationInfo: {
      count: 0,
      pageSize: this.pageSize,
      totalPage: 0,
      currentPage: 0,
      isFirstPage: true,
      isLastPage: true,
    },
    selectedTaskIds: [],
  };

  refresh = () => {
    this.refreshTask();
    this.setState({ selectedTaskIds: [] });
  }

  refreshTask = () => {
    let queryStr = new URLSearchParams({
      page: this.curPage.toString(),
      pageSize: this.pageSize.toString(),
    }).toString();
    fetch(`${Routers.listTask}?${queryStr}`, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data: any) => {
        this.setState({
          rows: data.tasks.map((task: any) => ({
            key: task.tid,
            name: task.name,
            type: task.by,
            path: task.path,
            createdTime: task.create_time,
            status: task.status,
          })),
          paginationInfo: {
            count: data.recordCount,
            pageSize: data.pageSize,
            totalPage: data.totalPage,
            currentPage: data.currentPage,
            isFirstPage: data.isFirstPage,
            isLastPage: data.isLastPage,
          },
        });
      });
  };

  constructor(props: Props) {
    super(props);
    this.refreshTimer = undefined;
  }

  isAutoRefresh: boolean = false;
  refreshTimer?: ReturnType<typeof setInterval>;
  componentDidMount() {
    this.refresh();
  }

  setupRefreshTimer() {
    if (this.refreshTimer) {
      return;
    }
    this.refreshTimer = setInterval(() => {
      this.refreshTask();
    }, 5000);
  }

  destroyRefreshTimer() {
    if (this.refreshTimer) clearInterval(this.refreshTimer);
    this.refreshTimer = undefined;
  }

  componentDidUpdate() {
    if (this.props.currentActiveTab !== "tasks") {
      this.destroyRefreshTimer();
      return;
    }

    this.shouldAutoRefresh(this.isAutoRefresh);
  }

  shouldAutoRefresh = (enabled: boolean) => {
    if (enabled) {
      this.setupRefreshTimer();
    } else {
      this.destroyRefreshTimer();
    }
  }

  componentWillUnmount() {
    this.destroyRefreshTimer();
  }

  handleSelectedTaskChange = (keys: React.Key[]) => {
    this.setState({
      selectedTaskIds: keys.map((key: React.Key) => key.toString()),
    });
  };

  handlePageChange = (pageNumber: number, pageSize: number | undefined) => {
    this.curPage = pageNumber;
    this.pageSize = pageSize !== undefined ? pageSize : this.pageSize;
    this.refreshTask();
  };

  kill = () => {
    for (let tid of this.state.selectedTaskIds) {
      this.killTask(tid);
    }
    this.refresh();
  };

  killTask = (key: React.Key | string) => {
    let key_ = typeof key === "string" ? key : key.toString();
    fetch(Routers.killTask, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
      body: JSON.stringify({ tid: key_ }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        console.log(data);
      });
  };

  gotoTask = (key: React.Key | string) => {
    const targetRow = this.state.rows.find(item => item.key === key);
    if (targetRow === undefined) {
      console.warn("targetRow not found for key", key);
      return;
    }
    const gotoLocation = `/fileManager${encodeURI(targetRow.path)}` + (targetRow.path.endsWith('/') ? '' : '/');
    window.open(gotoLocation, '_blank');
  };

  onTaskRowClick = (row: Row, rowIndex: number | undefined) => {
    let newSelectedTasks = this.state.selectedTaskIds.filter(
      (value: string) => value !== row.key
    );
    if (newSelectedTasks.length === this.state.selectedTaskIds.length) {
      newSelectedTasks.push(row.key);
    }
    this.setState({
      selectedTaskIds: newSelectedTasks,
    });
  };

  onTaskRowDoubleClick = (row: Row, rowIndex: number | undefined) => {
  };

  onAutoRefreshChange = (checked: boolean) => {
    this.isAutoRefresh = checked;
    this.shouldAutoRefresh(checked);
    if (checked) this.refresh();
  }


  render() {
    return (
      <>
        <Affix offsetTop={10}>
          <OperateButtonGroup
            selectedTaskIds={this.state.selectedTaskIds}
            tableRefresh={this.refresh}
            kill={this.kill}
          />
        </Affix>
        <Switch onChange={this.onAutoRefreshChange} checkedChildren="自动刷新" unCheckedChildren="手动刷新" />
        <TaskTable
          rows={this.state.rows}
          onSelectedTaskChange={this.handleSelectedTaskChange}
          selectedRowKeys={this.state.selectedTaskIds}
          killTask={this.killTask}
          gotoTask={this.gotoTask}
          onRowClick={this.onTaskRowClick}
          onRowDoubleClick={this.onTaskRowDoubleClick}
        />
        <MyPagination
          paginationInfo={this.state.paginationInfo}
          onPageChange={this.handlePageChange}
        />
      </>
    );
  }
}

export default Task;
