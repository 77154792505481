import { useEffect, useRef } from "react";
import { useForceUpdate } from "../utils/utils";
import "./videojsPlayer.css";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { PlaylistItem } from "../utils/interfaces";
import { List, Select } from "antd";
import "../utils/common.css";
const { Option } = Select;

interface VideoJsPlayerProp {
  options: videojs.PlayerOptions;
  onReady: (
    player: videojs.Player,
    subtitleOffsetBtns: videojs.Button[],
    previousNextBtns: videojs.Button[]
  ) => void;
  playlist: PlaylistItem[];
  onPlaylistItemChange: (item: PlaylistItem, index: number) => void;
}

const VideoPlayer = (props: VideoJsPlayerProp) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player>();
  const previousItemBtn = useRef<videojs.Button>();
  const nextItemBtn = useRef<videojs.Button>();
  const togglePlaylistBtn = useRef<videojs.Button>();
  const { options, onReady, playlist, onPlaylistItemChange } = props;

  const cachedPlaylist = useRef<PlaylistItem[]>([]); // to check whether browsering other files
  const currentIndex = useRef<number>(0); // use ref instead of state because callback function(onClick, etc) won't read right state
  const playListVisible = useRef<boolean>(false); // use ref instead of state because callback function(onClick, etc) won't read right state
  const forceUpdate = useForceUpdate(); // rerender for currentIndex which is not state

  const loopMode = useRef<string>("queueLoop");

  const addSubtitleOffset = (player: videojs.Player, offset: number) => {
    Array.from(player.remoteTextTracks()).forEach((track) => {
      if (track.mode === "showing" && track.cues) {
        Array.from(track.cues).forEach((cue) => {
          cue.startTime += offset || 0.5;
          cue.endTime += offset || 0.5;
        });
      }
    });
  };

  const checkItemBtnVisible = (currentIndex_: number) => {
    if (previousItemBtn.current) {
      let previousBtn = previousItemBtn.current;
      currentIndex_ === 0 ? previousBtn.hide() : previousBtn.show();
    }
    if (nextItemBtn.current) {
      let nextBtn = nextItemBtn.current;
      currentIndex_ < playlist.length - 1 ? nextBtn.show() : nextBtn.hide();
    }
  };

  const gotoItem = (newCurrentIndex: number) => {
    if (newCurrentIndex >= 0 && newCurrentIndex < playlist.length) {
      console.log("Goto item", newCurrentIndex);
      currentIndex.current = newCurrentIndex;
      forceUpdate();
    }
    checkItemBtnVisible(newCurrentIndex);
  };

  const addPreviousNextBtns = (player: videojs.Player) => {
    let Button = videojs.getComponent("Button");
    previousItemBtn.current = new Button(player, {});
    let previousBtn = previousItemBtn.current;
    previousBtn.controlText("Previous item in playlist");
    previousBtn.addClass("vjs-icon-previous-item");
    previousBtn.handleClick = () => {
      gotoItem(currentIndex.current - 1);
    };

    nextItemBtn.current = new Button(player, {});
    let nextBtn = nextItemBtn.current;
    nextBtn.controlText("Next item in playlist");
    nextBtn.addClass("vjs-icon-next-item");
    nextBtn.handleClick = () => {
      gotoItem(currentIndex.current + 1);
    };

    player.controlBar.addChild(nextBtn, null, 1);
    player.controlBar.addChild(previousBtn, null, 1);
    return [previousBtn, nextBtn];
  };

  const addPlaylistBtn = (player: videojs.Player) => {
    let Button = videojs.getComponent("Button");
    let playlistBtn = new Button(player, {});
    playlistBtn.controlText("Toggle playlist");
    playlistBtn.addClass("icon-playlist");
    playlistBtn.handleClick = () => {
      playListVisible.current = !playListVisible.current;
      forceUpdate();
    };
    player.controlBar.addChild(playlistBtn);
    togglePlaylistBtn.current = playlistBtn;
    return playlistBtn;
  };

  const addSubOffsetBtns = (player: videojs.Player) => {
    let Button = videojs.getComponent("Button");
    let minusOffsetBtn = new Button(player, {});
    minusOffsetBtn.controlText("Subtitles offset -500ms");
    minusOffsetBtn.addClass("icon-subtitle-minus");
    minusOffsetBtn.handleClick = () => {
      addSubtitleOffset(player, -0.5);
    };

    let plusOffsetBtn = new Button(player, {});
    plusOffsetBtn.controlText("Subtitles offset +500ms");
    plusOffsetBtn.addClass("icon-subtitle-plus");
    plusOffsetBtn.handleClick = () => {
      addSubtitleOffset(player, +0.5);
    };

    player.controlBar.addChild(plusOffsetBtn);
    player.controlBar.addChild(minusOffsetBtn);
    return [minusOffsetBtn, plusOffsetBtn];
  };

  const addSeekBtns = (player: videojs.Player) => {
    let Button = videojs.getComponent("Button");
    let backBtn = new Button(player, {});
    backBtn.controlText("Seek back for 10s");
    backBtn.addClass("icon-seek-back");
    backBtn.handleClick = () => {
      player.currentTime(player.currentTime() - 10);
    };

    let forwardBtn = new Button(player, {});
    forwardBtn.controlText("Seek forward for 10s");
    forwardBtn.addClass("icon-seek-forward");
    forwardBtn.handleClick = () => {
      player.currentTime(player.currentTime() + 10);
    };

    player.controlBar.addChild(forwardBtn, null, 1);
    player.controlBar.addChild(backBtn, null, 1);
  };

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      cachedPlaylist.current = playlist;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        addSeekBtns(player);
        onReady &&
          onReady(
            player,
            addSubOffsetBtns(player),
            addPreviousNextBtns(player)
          );
        addPlaylistBtn(player);
        checkItemBtnVisible(currentIndex.current);
        player.on("ended", () => {
          let currentIndex_ = currentIndex.current;
          let playlist_ = cachedPlaylist.current;
          if(!playlist_) return;
          switch (loopMode.current) {
            case "":
            case "noLoop":
              break;
            case "singleLoop":
              player.play();
              break;
            case "queueLoop":
              if (currentIndex_ < playlist_.length - 1) {
                gotoItem(currentIndex_ + 1);
                // forceUpdate();
                setTimeout(() => {
                  player.play();
                }, 1000);
              }
              break;
            case "endlessQueueLoop":
              if (currentIndex_ < playlist_.length - 1) {
                gotoItem(currentIndex_ + 1);
              } else {
                gotoItem(0);
              }
              setTimeout(() => {
                player.play();
              }, 1000);
              break;
          }
        });
      }));
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      options.autoplay !== undefined && player.autoplay(options.autoplay);
      options.controls !== undefined && player.controls(options.controls);

      if (JSON.stringify(cachedPlaylist.current) !== JSON.stringify(playlist)) {
        currentIndex.current = 0;
        cachedPlaylist.current = playlist;
        checkItemBtnVisible(currentIndex.current);
      }

      if (playlist.length > 1) {
        togglePlaylistBtn.current && togglePlaylistBtn.current.show();
      } else {
        togglePlaylistBtn.current && togglePlaylistBtn.current.hide();
      }

      let currentIndex_ = currentIndex.current;
      if (
        playlist[currentIndex_] &&
        player.src() !== playlist[currentIndex_].src
      ) {
        player.src(playlist[currentIndex_].src);
        onPlaylistItemChange(playlist[currentIndex_], currentIndex_);
      }
    }
  });

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = undefined;
      }
    };
  }, []);

  const handleLoopModeChange = (value: string) => {
    loopMode.current = value;
  };

  const path = require("path");
  return (
    <>
      <div data-vjs-player style={{ width: "100%", overflow: "auto" }}>
        <video
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          preload="auto"
        />
      </div>
      {playlist.length > 0 && playListVisible.current && (
        <>
          <h1>Playlist</h1>
          <span>Loop mode: </span>
          <Select
            defaultValue="queueLoop"
            style={{ width: 170 }}
            onChange={handleLoopModeChange}
          >
            <Option value="noLoop">No Loop</Option>
            <Option value="singleLoop">Single Loop</Option>
            <Option value="queueLoop">Queue Loop</Option>
            <Option value="endlessQueueLoop">Endless Queue Loop</Option>
          </Select>
          <List
            itemLayout="horizontal"
            dataSource={playlist}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <button
                      type="button"
                      disabled={currentIndex.current === index}
                      className="button-like-a-link"
                      onClick={() => {
                        gotoItem(index);
                        forceUpdate();
                      }}
                    >
                      {path.basename(item.key)}
                    </button>
                  }
                  description={currentIndex.current === index ? "Playing" : ""}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </>
  );
};

export default VideoPlayer;
