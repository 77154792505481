import { useState } from 'react';
import { Button, Form, Input, Select, message, Space } from 'antd';
import ModalCollectionForm from './../../utils/modalCollectionForm';
const { Option } = Select;

interface MoveToButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const MoveToButton = (props: MoveToButtonProps) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(true);

    let dstList = values.tasks.map((task: any) => task.dst);
    let moveModes = values.tasks.map((task: any) => task.moveMode);

    const response = await props.post(props.postUrl, {
      srcList: props.selectedFiles, 
      dstList: dstList,
      moveModes: moveModes,
    });

    if(!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if(json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
    setConfirmLoading(false);
    setVisible(false);
  }

  const formItems = () => {
    return (
        <Form.List name="tasks">
          {(fields) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  label="Source"
                  name={[name, 'src']}
                  fieldKey={[fieldKey, 'src']}
                  rules={[{ required: true, message: 'Missing source ' }]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="Destination"
                  name={[name, 'dst']}
                  fieldKey={[fieldKey, 'dst']}
                >
                  <Input />
                </Form.Item>

                <Form.Item 
                  {...restField}
                  label="Move Mode" 
                  name={[name, 'moveMode']}
                  fieldKey={[fieldKey, 'moveMode']}
                >
                  <Select defaultValue="move">
                    <Option value="move">Move</Option>
                    <Option value="copy">Copy</Option>
                    <Option value="symlink">Soft Link</Option>
                  </Select>
                </Form.Item>
              </Space>
            ))}
          </>
        )}
        </Form.List>
    )
  }

  const initialValues = {
    tasks:
      props.selectedFiles.map(file => ({
        src: file,
        dst: file,
        moveMode: "move",
      })),
  }
  return (
    <div>
      <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        MoveTo
      </Button>
      <ModalCollectionForm
        title="MoveTo"
        visible={visible}
        initialValues={initialValues}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
        }}
        onSubmit={handleSubmit}
        formItems={formItems}
      />
    </div>
  );
};

export default MoveToButton;
