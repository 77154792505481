import React from "react";
import { Button } from "antd";

import ArchiveButton from "./buttons/archive";
import ExtractButton from "./buttons/extract";
import DeleteButton from "./buttons/delete";
import ConvertButton from "./buttons/convert";
import GenPlaylistButton from "./buttons/genPlaylist";
import SegmentButton from "./buttons/segment";
import SplitButton from "./buttons/split";
import MoveToButton from "./buttons/moveTo";
import ShareButton from "./buttons/share";
import SyncButton from "./buttons/sync";
import EmbedSubButton from "./buttons/embedSub";
import UnwrapDirectoryButton from "./buttons/unwrapDirectory";
import { Routers } from "../apiRouter";

interface Props {
  selectedFiles: string[];
  curDir: string;
  onBrowserFilesChange: (files: string[]) => void;
  post: (url: string, data: any) => Promise<Response>;
  get: (urlString: string, query: any) => Promise<Response>;
}

interface State {
  points: string[];
  srcSelected: number;
  dstSelected: number;
}

class FileOperateButtonGroup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      points: [],
      srcSelected: 0,
      dstSelected: 0,
    };
    this.props
      .get(Routers.syncMountPoint, {})
      .then((response: Response) => response.json())
      .then((data) => {
        this.setState({
          points: data.points,
          srcSelected: data.src_selected,
          dstSelected: data.dst_selected,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  notifyBrowserFilesChange = () => {
    this.props.onBrowserFilesChange(this.props.selectedFiles);
  };

  render() {
    return (
      <>
        <div className="ant-btn-group">
          {/* source files */}
          <Button onClick={this.notifyBrowserFilesChange}>Browse</Button>
          <ArchiveButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.archive}
          />
          <ExtractButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.extract}
          />
          <DeleteButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.delete}
          />
          <ConvertButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.convert}
          />
          <SegmentButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.segment}
          />
          <SplitButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.split}
          />
          <MoveToButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.moveTo}
          />
          <ShareButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.share}
          />
          <SyncButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.sync}
            mountPoints={this.state.points}
            srcSelected={this.state.srcSelected}
            dstSelected={this.state.dstSelected}
          />
          <GenPlaylistButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.genPlaylist}
          />
          <EmbedSubButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.embedSub}
            get={this.props.get}
            getUrl={Routers.getVidSubPairInDir}
          />
          <UnwrapDirectoryButton
            selectedFiles={this.props.selectedFiles}
            post={this.props.post}
            postUrl={Routers.unwrapDirectory}
          />
        </div>
      </>
    );
  }
}
export default FileOperateButtonGroup;
