/*
 * @Author: your name
 * @Date: 2021-10-15 10:37:48
 * @LastEditTime: 2021-10-21 11:11:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/loginToken.tsx
 */
import { Routers } from "./apiRouter";

export const checkRefreshToken = async () => {
  const succuss = await fetch(Routers.refreshToken, {
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    cache: "no-cache",
    redirect: "manual",
  }).then((response) => {
    if (!response.ok) return false;
    if (response.status === 303) return false;
    return true;
  });
  return succuss;
};
