import { Button, Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

interface ConvertButtonProps {
  selectedFiles: string[];
  postUrl: string;
  post: (url: string, data: any) => Promise<Response>;
}

const ConvertButton = (props: ConvertButtonProps) => {

  const convert = async (files: string[]) => {
    const response = await props.post(props.postUrl, {
      fileList: files, 
    });

    if(!response.ok) {
      message.error("HTTP request error");
    } else {
      let json = await response.json();
      if(json.code !== 0) {
        message.error(`${json.msg}: ${json.err}`);
      } else {
        message.success(json.msg);
      }
    }
  }

  const path = require('path');
  const showConvertConfirm = () => {
    confirm({
      title: 'Are you sure convert these files?',
      icon: <ExclamationCircleOutlined />,
      content: props.selectedFiles.map(file => path.basename(file)).reduce((previousValue, currentValue) => `${previousValue}, ${currentValue}`),
      onOk() {
        convert(props.selectedFiles);
      },
    });
  }

  return (
    <>
      <Button onClick={showConvertConfirm}>
        Convert
      </Button>
    </>
  );
};

export default ConvertButton;
