import React, { useState } from "react";
import OperateButtonGroup from "./operateButtonGroup";
import FileTable from "./fileTable";
import {
  FileRow,
  FileInfo,
  HistoryRow,
  FileBreadcrumbInfo,
} from "./interfaces";
import Browser from "./browser";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane";
import { Progress, message, Button, Affix, Spin } from "antd";
import { PaginationInfo } from "../utils/interfaces";
import { Routers } from "../apiRouter";
import { Subtitle } from "../utils/interfaces";
import FileBreadcrumb from "./fileBreadcrumb";
import { openInNewTab, getFileTypeImage } from "../utils/utils";
import { MediaType } from "../utils/interfaces";
import { RouteComponentProps } from "react-router";

interface MatchParams {
  0: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

interface Quota {
  free: number;
  total: number;
  used: number;
}

interface State {
  dir: string;
  sortBy: string;
  order: string;
  selectedFiles: string[];
  selectedKeys: React.Key[];
  fileRows: FileRow[];
  historyRows: HistoryRow[];
  historyPaginationInfo: PaginationInfo;
  historyPageNumber: number;
  historyPageSize: number;
  breadcrumbInfos: FileBreadcrumbInfo[];
  quota: Quota;
  preMediaBtnDisabled: boolean;
  nextMediaBtnDisabled: boolean;
  videoJsOptions: {
    autoplay: boolean;
    controls: boolean;
    sources: { src: string; type: string }[];
  };
  browserFiles: string[];
  curMediaType: MediaType;
  mediaUrl: string;
  locatedName: string;
  fileLoading: boolean;
}

class FileManager extends React.Component<Props, State> {
  curDirFiles: FileInfo[];
  breadcrumbInfos: FileBreadcrumbInfo[];
  fileKey2FileInfo: { [index: string]: FileInfo };
  videoLastProgressSecond: number;
  deletedProgressVideo: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFiles: [],
      selectedKeys: [],
      fileRows: [],
      dir: "/",
      sortBy: "name",
      order: "asc",
      historyRows: [],
      historyPaginationInfo: {
        count: 0,
        pageSize: 10,
        totalPage: 1,
        currentPage: 1,
        isFirstPage: false,
        isLastPage: false,
      },
      historyPageNumber: 1,
      historyPageSize: 10,
      breadcrumbInfos: [],
      quota: { free: 0, total: 0, used: 0 },
      preMediaBtnDisabled: true,
      nextMediaBtnDisabled: true,
      videoJsOptions: { autoplay: false, controls: true, sources: [] },
      browserFiles: [],
      curMediaType: MediaType.None,
      mediaUrl: "",
      locatedName: "",
      fileLoading: false,
    };
    this.curDirFiles = [];
    this.fileKey2FileInfo = {};
    this.breadcrumbInfos = [];
    this.deletedProgressVideo = "";
    this.videoLastProgressSecond = 0.0;
  }

  componentDidMount() {
    this.refresh();
  }

  prevLocation: any;
  componentDidUpdate() {
    if (this.props.location !== this.prevLocation) {
      this.prevLocation = this.props.location;
      this.refresh();
    }
  }

  getQuota = () => {
    fetch(Routers.getQuota, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data: any) => {
        this.setState({
          quota: { free: data.free, used: data.used, total: data.total },
        });
      });
  };

  refresh = (gotoDir: string = "") => {
    this.setState({fileLoading: true});
    console.log(this.props.match);
    let files: FileInfo[];
    if (gotoDir !== "") {
      this.props.history.push(`/fileManager${gotoDir}`);
      return;
    } else {
      let params = decodeURIComponent(this.props.match.params[0]);
      gotoDir = params === "" ? "/" : params;
    }
    let queryStr = new URLSearchParams({
      dir: gotoDir,
      sortBy: this.state.sortBy,
      order: this.state.order,
    }).toString();
    fetch(`${Routers.listDir}?${queryStr}`, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    })
      .then((response) => {
          if (!response.ok) {
            if(response.status === 404) {
              message.error("The directory doesn't exist, please go back");
              if(this.props.history.length <= 2) {
                this.props.history.push("");
              }
            }
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        return response.json();
      })
      .then((data: any) => {
        files = data.files;
        this.breadcrumbInfos = data.breadcrumbDirs.map(
          (breadcrumbDir: any) => ({
            name: breadcrumbDir.name,
            path:
              "/fileManager" +
              `${breadcrumbDir.href}/`.split('/').map((s: string) => {return encodeURIComponent(s);}).join('/'),
          })
        );
        let newState: any = {
          fileRows: files.map((file: FileInfo) => ({
            key: file.path,
            name: file.name,
            size: file.size,
            modifiedTime: file.modTime,
            ModTime: file.ModTime,
            Size: file.Size,
            isDir: file.isDir,
            fileType: file.fileType,
            accessToken: file.accessToken,
          })),
          breadcrumbInfos: this.breadcrumbInfos,
          selectedFiles: [],
          selectedKeys: [],
        };
        if (this.state.dir !== gotoDir) {
          newState.dir = gotoDir;
        }
        this.setState(newState);
        this.curDirFiles = files;
        this.fileKey2FileInfo = {};
        files.forEach((value: FileInfo) => {
          this.fileKey2FileInfo[value.path] = value;
        });
        window.scrollTo(0, 0);
      })
      .finally(() => {
        this.setState({fileLoading: false});
      });
    this.getQuota();
  };

  updateSelectedFiles = (keys: React.Key[]) => {
    this.setState({
      selectedFiles: keys.map((key) => key.toString()),
      selectedKeys: keys,
    });
  };

  deleteReq = (urlString: string, data: object) => {
    return fetch(urlString, {
      method: "DELETE",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
      body: JSON.stringify(data),
    });
  };

  post = (urlString: string, data: object) => {
    let headers = {
      "Content-Type": "application/json",
    };
    return this.postRaw(urlString, headers, JSON.stringify(data));
  };

  postRaw = (urlString: string, headers: any, data: any) => {
    return fetch(urlString, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: headers,
      cache: "no-cache",
      body: data,
    });
  };

  get = (urlString: string, query: any) => {
    let queryStr = new URLSearchParams(query).toString();
    return fetch(`${urlString}?${queryStr}`, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      cache: "no-cache",
    });
  };

  deleteHistory = (key: React.Key) => {
    this.post(Routers.deleteHistory, { ids: [parseInt(key.toString())] })
      .then((res) => {
        this.refreshHistory();
      })
      .catch((reason) => {
        message.error(reason);
      });
  };

  refreshHistory = () => {
    this.get(Routers.listHistory, {
      page: this.state.historyPageNumber,
      pageSize: this.state.historyPageSize,
    })
      .then((res) => res.json())
      .then((data) => {
        let histories = data.histories;
        let paginationInfo = {
          count: data.recordCount,
          pageSize: data.pageSize,
          totalPage: data.totalPage,
          currentPage: data.currentPage,
          isFirstPage: data.isFirstPage,
          isLastPage: data.isLastPage,
        };
        let rows: HistoryRow[] = histories.map((history: any) => ({
          key: history.id,
          name: history.fileName,
          dir: history.directory,
          accessTime: history.accessTime,
        }));
        this.setState({
          historyPaginationInfo: paginationInfo,
          historyRows: rows,
        });
      })
      .catch((reason) => {
        message.error(reason);
      });
  };

  cleanHistory = () => {
    this.post(Routers.cleanHistory, {})
      .then((res) => {
        this.refreshHistory();
      })
      .catch((reason) => {
        message.error(reason);
      });
  };

  locateHistory = (dir: string, name: string) => {
    this.setState({ locatedName: name }, () => this.refresh(dir));
  };

  handleLocatedOver = () => {
    this.setState({ locatedName: "" });
  };

  historyPageChange = (pageNumber: number, pageSize: number | undefined) => {
    let update = {
      historyPageNumber: pageNumber,
      historyPageSize: this.state.historyPageSize,
    };
    if (typeof pageSize !== "undefined") {
      update.historyPageSize = pageSize;
    }
    this.setState(update, this.refreshHistory);
  };

  onFileRowClick = (row: FileRow, _: number | undefined) => {
    let newSelectedFiles = this.state.selectedFiles.filter(
      (value: string) => value !== row.key
    );
    if (newSelectedFiles.length === this.state.selectedFiles.length) {
      newSelectedFiles.push(row.key);
    }
    this.setState({
      selectedFiles: newSelectedFiles,
      selectedKeys: newSelectedFiles,
    });
  };

  onFileRowDoubleClick = (row: FileRow, _: number | undefined) => {
    let fileInfo = this.fileKey2FileInfo[row.key];
    if (fileInfo.isDir) {
      this.props.history.push(`${encodeURIComponent(fileInfo.name)}/`);
    } else {
      openInNewTab(this.fileUrlBuilder(fileInfo.path));
    }
  };

  handleVideoProgress = (
    curMediaFile: string,
    currentTime: number,
    duration: number
  ) => {
    if (currentTime === 0) return;
    if (duration - currentTime <= 120.0) {
      if (this.deletedProgressVideo !== curMediaFile) {
        this.deletedProgressVideo = curMediaFile;
        this.deleteReq(Routers.videoProgress, { curMediaFile });
      }
      return;
    }
    if (
      currentTime >= 10.0 &&
      Math.abs(currentTime - this.videoLastProgressSecond) >= 5.0
    ) {
      this.videoLastProgressSecond = currentTime;
      this.post(Routers.videoProgress, {
        videoFullPath: curMediaFile,
        currentTime: currentTime,
      });
    }
  };

  getVideoProgress = async (videoUrl: string) => {
    const res = await this.get(Routers.videoProgress, {
      videoFullPath: videoUrl,
    })
      .then((response) => {
        if (!response.ok) throw new Error("HTTP request error");
        return response.json();
      })
      .then((data) => {
        return { hasProgress: data.hasRecord, progress: data.currentTime };
      })
      .catch((err) => {
        console.error("Error:", err.message);
      });
    if (res === undefined) {
      return { hasProgress: false, progress: 0 };
    }
    return { hasProgress: res.hasProgress, progress: res.progress };
  };

  fileUrlBuilder = (file: string, record: boolean = true) => {
    return `${Routers.rawFile}${file.split("/").map((s: string) => encodeURIComponent(s)).join("/") }?recordHistory=${record.toString()}`;
  };

  tempFileUrlBuilder = (file: string, accessToken: string) => {
    return `${Routers.tempRawFile}${file.split("/").map((s: string) => encodeURIComponent(s)).join("/") }?accessToken=${encodeURIComponent(accessToken)}`;
  };

  getSub = async (file: string) => {
    let subs: void | Subtitle[] = await this.get(
      Routers.listSubtitles + file,
      {}
    )
      .then((res) => res.json())
      .then((data) =>
        Object.keys(data.subtitles).map((key: string) => ({
          language: key,
          url: this.fileUrlBuilder(data.subtitles[key], false),
        }))
      )
      .catch((reason) => {
        console.error("Error:", reason);
      });
    if (subs === undefined) {
      return [{ language: "network error", url: "" }];
    }
    return subs;
  };

  handleBrowserFilesChange = (newBrowserFiles: string[]) => {
    this.setState({ browserFiles: newBrowserFiles });
  };

  render() {
    return (
      <>
        <SplitPane split="vertical">
          <Pane>
            <div style={{ width: "100%", overflow: "auto" }}>
              <Progress
                percent={parseInt(
                  (
                    (this.state.quota.used / this.state.quota.total) *
                    100
                  ).toFixed()
                )}
              />
              <p>
                {this.state.quota.used}GB / {this.state.quota.total}GB
              </p>
              <Affix offsetTop={10}>
                <OperateButtonGroup
                  post={this.post}
                  postRaw={this.postRaw}
                  get={this.get}
                  selectedFiles={this.state.selectedFiles}
                  historyRows={this.state.historyRows}
                  deleteHistory={this.deleteHistory}
                  refreshHistory={this.refreshHistory}
                  cleanHistory={this.cleanHistory}
                  locateHistory={this.locateHistory}
                  refresh={this.refresh}
                  curDir={this.state.dir}
                  onBrowserFilesChange={this.handleBrowserFilesChange}
                  onPageChange={this.historyPageChange}
                  paginationInfo={this.state.historyPaginationInfo}
                />
                <ClipBoardButtonGroup
                  curDir={this.state.dir}
                  selectedFiles={this.state.selectedFiles}
                  post={this.post}
                  postUrl={Routers.moveTo}
                ></ClipBoardButtonGroup>
              </Affix>
              <FileBreadcrumb
                fileBreadcrumbInfos={this.state.breadcrumbInfos}
                goto={(path: string) => {
                  this.props.history.push(path);
                }}
              ></FileBreadcrumb>
              <Spin spinning={this.state.fileLoading} tip="Loading..." size="large">
                <FileTable
                  getFileTypeImage={getFileTypeImage}
                  onLocatedOver={this.handleLocatedOver}
                  locatedName={this.state.locatedName}
                  onRowClick={this.onFileRowClick}
                  onRowDoubleClick={this.onFileRowDoubleClick}
                  updateSelectedFiles={this.updateSelectedFiles}
                  selectedRowKeys={this.state.selectedKeys}
                  data={this.state.fileRows}
                  fileUrlBuilder={this.tempFileUrlBuilder}
                />
              </Spin>
            </div>
          </Pane>

          <Pane>
            <div style={{ width: "100%", overflow: "auto" }}>
              <Browser
                browserFiles={this.state.browserFiles}
                onVideoProgress={this.handleVideoProgress}
                fileUrlBuilder={this.fileUrlBuilder}
                getVideoProgress={this.getVideoProgress}
                getSub={this.getSub}
              />
            </div>
          </Pane>
        </SplitPane>
      </>
    );
  }
}

interface ClipBoardButtonGroupProps {
  selectedFiles: string[];
  curDir: string;
  post: (url: string, data: any) => void;
  postUrl: string;
}

enum ClipBoardOp {
  Copy = "copy",
  Move = "move",
  SymLink = "symlink",
}
interface ClipBoardRecord {
  path: string;
  op: ClipBoardOp;
}

const ClipBoardButtonGroup = (props: ClipBoardButtonGroupProps) => {
  const [clipBoard, setClipBoard] = useState<ClipBoardRecord[]>([]);

  const copy = () => {
    setClipBoard(
      props.selectedFiles.map((file) => ({ path: file, op: ClipBoardOp.Copy }))
    );
  };

  const cut = () => {
    setClipBoard(
      props.selectedFiles.map((file) => ({ path: file, op: ClipBoardOp.Move }))
    );
  };

  const paste = () => {
    if (clipBoard.length === 0) return;
    props.post(props.postUrl, {
      srcList: clipBoard.map((record) => record.path),
      dstList: Array(clipBoard.length).fill(props.curDir + "/"),
      moveModes: Array(clipBoard.length).fill(clipBoard[0].op.toString()),
    });
  };

  const pasteLink = () => {
    if (clipBoard.length === 0) return;
    props.post(props.postUrl, {
      srcList: clipBoard.map((record) => record.path),
      dstList: Array(clipBoard.length).fill(props.curDir + "/"),
      moveModes: Array(clipBoard.length).fill(ClipBoardOp.SymLink.toString()),
    });
  };
  return (
    <>
      <div className="ant-btn-group">
        {props.selectedFiles.length > 0 && (
          <>
            <Button onClick={copy}>Copy</Button>
            <Button onClick={cut}>Cut</Button>
          </>
        )}
        {clipBoard.length > 0 && (
          <>
            <Button onClick={paste}>Paste</Button>
            <Button onClick={pasteLink}>Paste Link</Button>
          </>
        )}
      </div>
    </>
  );
};

export default FileManager;
