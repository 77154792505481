/*
 * @Author: your name
 * @Date: 2021-10-15 17:12:12
 * @LastEditTime: 2021-10-21 11:09:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/shareManager/delShareBtn.tsx
 */

import { Button, Popconfirm } from "antd";

interface DelShareBtnProp {
  selectedFiles: string[];
  deleteSelectedShare: () => void;
}

const DelShareBtn = (props: DelShareBtnProp) => {
  return (
    <>
      <Popconfirm
        title={`Are you sure to delete share ${props.selectedFiles.join(" ")}`}
        onConfirm={props.deleteSelectedShare}
      >
        <Button>Delete</Button>
      </Popconfirm>
    </>
  );
};

export default DelShareBtn;
