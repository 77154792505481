/*
 * @Author: your name
 * @Date: 2021-10-12 14:52:57
 * @LastEditTime: 2021-10-26 20:45:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /myrclone-react/src/task/operateButtonGroup.tsx
 */
import React from "react";
import { Button } from "antd";
import { Routers } from "../apiRouter";

interface Props {
  selectedTaskIds: string[];
  tableRefresh: () => void;
  kill: () => void;
}

interface State {}

class OperateButtonGroup extends React.Component<Props, State> {
  // constructor(props: Props) {
  //   super(props);
  // }

  clean = () => {
    fetch(Routers.cleanTask, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then((data) => {
        console.log(data);
      });
  };

  render() {
    return (
      <>
        <Button onClick={this.props.tableRefresh}>Refresh</Button>
        <Button onClick={this.props.kill}>Kill</Button>
        <Button onClick={this.clean}>Clean</Button>
      </>
    );
  }
}

export default OperateButtonGroup;
